@import "_variables";
@import "_breakpoints";
// Page Design / Global components
@import "ceres/views/Item/single-item";
@import "ceres/views/PageDesign/header";
@import "ceres/views/PageDesign/footer";
@import "ceres/views/PageDesign/navigation";
@import "ceres/views/PageDesign/search-box";
@import "ceres/views/PageDesign/language-select";
@import "ceres/views/PageDesign/breadcrumb";
@import "ceres/views/PageDesign/formular";
@import "ceres/views/PageDesign/elements";
@import "ceres/views/ItemList/categoriegrid";
@import "ceres/views/ItemList/faq";
@import "ceres/views/responsive";
@import "ceres/views/Templates/CategoryListItem/category-list-item";
// Templates
@import "ceres/views/Templates/AddressSelect/address-select";
@import "ceres/views/Templates/ItemListSort/pagination";
// Pages
@import "ceres/views/ItemList/loading-animation";
@import "ceres/views/Home/home";
// Order Confirmation
@import "ceres/views/OrderConfirmation/order-confirmation";

body > div#vue-app {
  background: $cfourMainContentRed no-repeat center center;
  background-image: url('../images/bgcenter.jpg');
  padding: 0;
  //margin-top: 161px !important;
}

html > body {
  background: repeat-y 50% 0 #050505;
  background-attachment: fixed;
  background-image: url('../images/mauer.jpg');
}

div.container, div.container-max, header.container {
  max-width: 1030px;
}

.search-input {
  background: $cfourWhite;
}

.single.container-max.page-content {
  border: 0 none;
  background: $cfourTransparentColor;
  .singleitem-gray {
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#e5e5e5));
    background: linear-gradient(to bottom, #fff 0, #e5e5e5 100%);
    border: 2px solid #7a1315;
  }
}

.no-touch #mainNavbarCollapsable ul.mainmenu > li:hover {
  background-color: $cfourTransparentColor;
}

.search-box-shadow-frame {
  margin: 0;
  display: block;
  /*padding: 6px;*/

}

#mainNavbarCollapsable {
  ul.mainmenu > li > a {
    padding: 0;
  }
  ul.mainmenu {
    li {
      a {
        padding: 7px 4px;
        font-size: 16px;
        @media (min-width: 991px) and (max-width: 1199px) {
          font-size: 16px;
        }
      }
    }
  }
}

.search-submit {
  background: $cfourWhite;
  color: #888;
  border-radius: 0 4px 4px 0;
}

#login-change {
  font-size: 14px;
  color: $cfourWhite;
}

.top-bar {
  .list-inline {
    .list-inline-item {
      #login-change {
        a {
          span {
            color: $cfourWhite;
          }
        }
      }
      a {
        padding: 0.5rem .8rem;
      }
    }
  }
  .controls-list > li a:not(.btn):hover, .controls-list > li a:not(.btn)[aria-expanded="true"] {
    background-color: $cfourTransparentColor;
    color: #fff;
  }
  .controls-list > li {
    .basket-preview-hover {
      .basket-item {
        a {
          color: $cfourBlack !important;
          &:hover {
            color: $cfourBlack !important;
          }
        }
      }
    }
  }

  .brand-logo {
    .usp {
      li {
        i {
          color: #77b236;
          margin-right: 2px;
        }
      }
    }
  }
  .pipe {
    display: none;
  }
}

.list-inline-item.control-basket {
  font-size: 14px !important;
}

.add-to-basket-container .btn > .fa {
  float: none !important;
}

.navigation-tree {
  li {
    padding: 10px;
  }
}

.custom-texts {
  padding: 10px;
  font-size: 16px !important;
  line-height: 1.5 !important;
  color: $cfourBlack;

  h1 {
    line-height: 48px;
    font-size: 30px;
    font-weight: 300;
    margin: 12px 0;
    color: black;
    text-rendering: optimizelegibility;
  }
  strong {
    font-weight: 900;
  }
}

.thumb-inner {
  .thumb-title.small {
    color: $cfourBlack;
    font-size: 14px;
    line-height: 16px;
    height: 52px;
    margin-bottom: 8px;
    overflow: hidden;
    padding: 0;
    text-align: center;
  }

  .vat-porto-info.small.text-muted {
    display: none !important;
  }
  .prices {
    display: flex;
    align-items: center;
    justify-content: center;
    .price {
      font-size: 20px;
      line-height: 24px;
      color: $cfourPriceGreen !important;
      text-shadow: #000 1px 1px 0px;
    }
  }
  .price-view-port {
    display: none !important;
  }
}

.container-max.page-content {
  padding: 15px;
  h1 {
    color: $cfourBlack;
  }
}

.home {
  .payment-icons {
    .footer-links-payment {
      padding: 0;
      li {
        display: inline;
        a {
          img {
            width: 70px;
            padding-top: 5px;
            padding-right: 0;
          }
        }
      }
    }
  }
}
.payment-icons {
  .footer-links-payment {
    padding: 0;
    li {
      display: inline;
      a {
        img {
          width: 69px;
          padding-top: 5px;
          padding-right: 0;
        }
      }
    }
  }
}

.pe-richsnippets.footerLine.text-left {
  /*border-top: 1px solid #fff;*/
  padding-top: 10px;
}

.goog-te-gadget-icon {
  display: none;
}

.cmp.cmp-product-thumb.cmp-product-thumb--grid {
  border: 1px solid #000;
  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
  }
}

.vat.small.text-muted {
  color: $cfourBlack;
}

.tab-content {
  img {
    max-width: 99%;
  }
}

a {
  color: $cfourBlack;
}

a:hover {
  color: $cfourBlack;
}

.btn.btn-medium {
  width: 11rem;
}

.form-control {
  width: 100%;
  padding: 0.6rem 0.75rem;
}

.cmp-method-list {
  .method-list-item input:checked + label {
    box-shadow: inset 0 0 0 .1rem #ddd;
  }
}

.basket-preview-hover {
  .basketBtn {
    border: 1px solid $cfourBlack;
    color: $cfourBlack;
    &:hover {
      background: $cfourGradientBackground;
    }
  }
}

table {
  tbody {
    tr:first-child {
      //background: $cfourPrimaryColor;
      //color: $cfourWhite;
    }
  }
}

.container-max.page-content {
  color: $cfourBlack;
  background: linear-gradient(to bottom, #fff 0%, #E5E5E5 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#E5E5E5));
  border: 2px solid #7A1315;
  padding: 10px 20px;
}

#CfourItemQuestionsForm {
  .btn {
    background: $cfourGradientBackground;
    &:hover {
      background: $cfourGradientBackground;
    }
  }
}

.similar_article {
  .thumb-inner {
    display: flex;
    .add-to-basket-lg-container {
      display: none !important;
    }
    .thumb-image {
      width: 30%;
    }
    .thumb-content {
      width: 70%;
      .vat.small.text-muted {
        font-size: 10px;
      }
    }
    .thumb-meta + div,
    .owl-dots {
      display: none;
    }
    .thumb-title.small {
      text-align: left;
      height: auto;
      text-transform: none;
      font-size: 14px;
    }
    .prices {
      .price {
        font-size: 14px;
        color: inherit;
        text-shadow: none;
      }
    }
    .vat {
      display: none;
    }
  }
}

.categoriegrid.page-content.container-max {
  border: 0 none;
}

[data-pm="Credit_Card"], [data-pm="Credit_Card"] + .separator {
  display: none;
}
.cmp-product-thumb a {
  color:#ffffff;
}
/*body > #vue-app > .wrapper-top {
  z-index:2000;
}*/
.autocomplete-item-name {
  flex: 1;
}
.search-box-shadow-frame .autocomplete-suggestions > .autocomplete-suggestion {
  white-space: normal;
}
.home .product-list .col-12, .home .product-list .col-12 {
  margin: 0;
}
body .top-bar .controls-list > li a:not(.btn) {
  color: #fff;
  font-size: 13px;
}
