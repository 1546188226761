span.price.h1 {
    color: $cfourPriceGreen;
    font-size: 38px;
    text-shadow: 1px 1px #000;
    font-weight: bold;
}


.single {
    .nav-tabs {
      margin-top: 20px;
        .nav-item {
          margin-bottom: 6px;
            .nav-link.active,
            .nav-link.active:hover {
                background: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#e5e5e5)) !important;
                background-image: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(229, 229, 229) 100%);
                color: $cfourBlack;
                border: 1px solid #ddd;
                background-image: none !important;
            }
            .nav-link:hover {
                background:$cfourGradientBackground;
                color:$cfourWhite;
            }
            a {
              background:$cfourGradientBackground;
              color:$cfourWhite;
              padding: 10px 15px !important;
              line-height: 1.42857143;
              border: 1px solid #ddd;
              border-radius: 4px 4px 0 0 !important;
              &:hover {
                background:$cfourGradientBackground;
                color:$cfourWhite;
              }
              &.active, &.active:hover {
                background: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#e5e5e5)) !important;
                background-image: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(229, 229, 229) 100%);
                color: $cfourBlack;
                border: 1px solid #ddd;
                background-image: none !important;
              }

            }
        }
    }
    .single-description {
        font-size: 16px;
        color: $cfourBlack;
    }
    .vat {
        color: $cfourBlack !important;
        font-size: 16px;
    }
  .ratenzahlung {
    img {
      max-width: 225px;
      width: 100%;
      margin-bottom: 10px;
    }
  }
  #ratenzahlung-modal-wrapper {
   .paypal-installment-specific-promotion {
    .paypal-installment-specific-promotion .paypal-installment-specific-promotion-financing-option {
      background-color: transparent;
      border-bottom: 1px solid $cfourBlack;
      padding-bottom: 3px;
      margin-bottom: 7px;
      font-weight: 900;
      }
     a {
       display: none;
     }
    }
  }
  #ppButton {
    margin-left: 15px;
    margin-top: 15px;
  }
  .availability.badge span {
    white-space: normal;
  }
  #similar-articles {
    .col-md-3 {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}

span.articlenumber.small.text-muted {
    display: block;
    span,
    b {
        font-size: 14px;
        color: $cfourBlack;
    }
}

.btn-primary:focus, .btn-primary:hover {
        background-color: $cfourGradientBackground !important;
        border-color: #006080;
    }

.btn-primary {
        text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
        background-color: $cfourGradientBackground !important;
        border-color: #006080;
}

.btn {
        box-shadow: rgba(255, 255, 255, .2) 0 1px 0 inset, rgba(0, 0, 0, .0470588) 0 1px 2px;
        background-image: linear-gradient(to bottom, rgba(255,255,255,0.25) 0%, rgba(255,255,255,0) 100%);
        border-width: 1px;
        border-style: solid;
        border-radius: 3px;
        font-size: 16px;
        display: inline-block;
        padding: .5rem 1rem .5rem .5rem;
}

.producertag.h6.producer.text-muted {
        display: none;
}

.navbar {
        background-color: $cfourGradientBackground;
 }

.qty-box {
     border: 1px solid #dcdbd8;
        .qty-input {
            background: $cfourTransparentColor;
            color: $cfourBlack;
            outline: 0;
            height: 100%;
            width: 35px;
            padding: 0;
            /*border: 1px solid #dcdbd8;*/
            border: none;
            border-radius: .1rem 0 0 .1rem;
            border-right: none;
        }
        .qty-btn-container {
            border-left:none;
            background:$cfourWhite;
            .qty-btn > .qty-sign, .qty-btn > i {
                border-radius: 43px;
                border: 1px solid #dcdbd8;
                width: 17px;
                color: #7a7f7f;
            }
            .qty-btn:not(.disabled):hover {
                background: $cfourTransparentColor;
            }
            .qty-btn-seperator {
                background: $cfourTransparentColor;
            }
        }
    }

.qty-box .qty-input {
        background: $cfourWhite;
    }

.glyphicon-plus, .glyphicon-minus {
        color: #444 !important;
    }

.usp.list-unstyled.row li {
        font-size: 18px;
    }

.mainmenu.pull-lg-right li {
        font-size: 17px;
        padding: .65rem .45rem;
    }

.fa.fa-minus.qty-sign, .fa.fa-minus {
        color: #7a7f7f !important;
    }

#subnavigation h3 {
        font-size: 25px;
        line-height: 36px;
    }

.payment-icons h3 {
        font-size: 25px;
        line-height: 36px;
    }

.back-to-top.hidden-sm-down {
        background: $cfourBlack;
        opacity: 1 !important;
        text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
        border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.25);
        transition: all 0.3s ease !important;
        -webkit-box-shadow: 0 0 10px 3px #fff;
        box-shadow: 0 0 10px 3px #fff;
    }

.text-muted.small.add-to-wish-list {
    border: 1px solid #cccc;
    border-radius: 4px;
    padding: .55rem 4.5rem;
    font-size: 16px;
    color: #777 !important;
    width: 243px;
    float: left;
}

.tab-content {
        color: $cfourBlack;
        font-size: 16px;
        padding: 10px;
        border: 1px solid #ddd;
    }

.owl-carousel {
        .carousel-control:hover {
            i {
                color: #77b236;
            }
        }
        .owl-single-item-control {
            color: #999;
            font-size: 2rem;
        }
    }
    .owl-thumbs {
        .owl-thumb.active, .owl-thumb:hover {
            border: 1px solid $cfourBlack;
            box-shadow: 0 0 5px #333;
        }
    }
    #single-carousel {
        .owl-item {
            img {
                border: 1px solid #ddd;
            }
        }
    }

.alert-info {
  background-color: #C30101 !important;
  border-color: #C30101 !important;
  color: #fff !important;
}
//#vue-app {
//  .input-unit {
//    width: 251px;
//  }
//}

.list-controls-inner{
  background: $cfourGradientBackground !important;
  .list-wrapper{
    color: $cfourWhite !important;
    .custom-select{
      padding: 0.1rem 2rem 0.1rem 1rem;
      background-position: calc(100% - 1rem) 0.41rem;
    }
  }
}
#CfourCrossselling {
  float: left;
  padding: 0 15px;
  width: 100%;
}
#CfourCrossselling .cmp-product-thumb .vat {
  display: none;
}

#CfourItemQuestionsForm {
  button {
    color: $cfourWhite;
  }
}

#add-item-to-basket-overlay #ppButton {
  padding: 0 15px 15px;text-align: right;
}
.controls-list #ppButton {
  text-align: right;
  margin-right: -0.8rem;
}
.single .paypal-installment-specific-promotion {
  border: none;
  padding: 0;
  h4 {
    font-size: 1em;
  }
}
