.categoriegrid.page-content.container-max {
    background: none;
    .category-title{
      font-size: 27px;
      color: $cfourBlack;
    }
  .category-description{
    color: $cfourBlack;
  }
}

.page-category {
  .page-content {
    @media (max-width: 767px) {
      margin-top: 35px;
    }
    @media (max-width: 439px) {
      margin-top: 55px;
    }
    @media (max-width: 399px) {
      margin-top: 80px;
    }
  }
}

.cmp-product-thumb {
   .add-to-basket-lg-container {
       border-color: transparent $cfourBlack transparent transparent;
       color: $cfourWhite;

     &:hover{
       border-color: transparent $cfourPriceGreen transparent transparent;

       & > i{
         color: #fff;
       }
     }
  }
}
.special-tags{
  .bg-primary {
    background-color: #77b236 !important;
    text-transform: uppercase;
    font-weight: 900 !important;
  }
}

.list-controls {
  .list-itemsperpage, .list-sort {
    display: inline-flex;
    white-space: nowrap;
    align-items: baseline;
    padding: 0 10px;
    label {
      margin-right: 1em;
    }
  }
}

html .wrapper-main .breadcrumb {
    font-size: 16px;
    background: transparent;
    @media (min-width: 768px) {
      padding-top: 20px !important;
    }
      .breadcrumb-item {
       color: $cfourWhite;
       a {
         color: $cfourWhite
       }
     }
  }



.navbar-brand {
     img {
      max-height: 5rem;
      margin-bottom: -36px;
      margin-left: 32px;
  }
}

.details-button{
  position:relative;
  background: $cfourGradientBackground;
  text-align: center;
  display: inline-block;
  width: 164px;
  left: 50%;
  margin-left: -87px;
  margin-bottom: 8px;
  a{
    color: $cfourWhite;
    font-weight: bold;
    &:hover{
      color: $cfourWhite;
      font-weight: bold;
    }
  }
}

.categorybox{
  background: $cfourSubnavigationBackground;
  border: 1px solid black;
  padding: 10px;
  margin-bottom: 15px;
}

/*#CfourTileListing {*/
  .square-container {
    width: 100%;
    display: block;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
  }
/*}*/