span.price.h1 {
  color: #77b236;
  font-size: 38px;
  text-shadow: 1px 1px #000;
  font-weight: bold; }

.single .nav-tabs {
  margin-top: 20px; }
  .single .nav-tabs .nav-item {
    margin-bottom: 6px; }
    .single .nav-tabs .nav-item .nav-link.active,
    .single .nav-tabs .nav-item .nav-link.active:hover {
      background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#e5e5e5)) !important;
      background-image: linear-gradient(to bottom, white 0%, #e5e5e5 100%);
      color: #000;
      border: 1px solid #ddd;
      background-image: none !important; }
    .single .nav-tabs .nav-item .nav-link:hover {
      background: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
      color: #fff; }
    .single .nav-tabs .nav-item a {
      background: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
      color: #fff;
      padding: 10px 15px !important;
      line-height: 1.42857143;
      border: 1px solid #ddd;
      border-radius: 4px 4px 0 0 !important; }
      .single .nav-tabs .nav-item a:hover {
        background: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
        color: #fff; }
      .single .nav-tabs .nav-item a.active, .single .nav-tabs .nav-item a.active:hover {
        background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#e5e5e5)) !important;
        background-image: linear-gradient(to bottom, white 0%, #e5e5e5 100%);
        color: #000;
        border: 1px solid #ddd;
        background-image: none !important; }

.single .single-description {
  font-size: 16px;
  color: #000; }

.single .vat {
  color: #000 !important;
  font-size: 16px; }

.single .ratenzahlung img {
  max-width: 225px;
  width: 100%;
  margin-bottom: 10px; }

.single #ratenzahlung-modal-wrapper .paypal-installment-specific-promotion .paypal-installment-specific-promotion .paypal-installment-specific-promotion-financing-option {
  background-color: transparent;
  border-bottom: 1px solid #000;
  padding-bottom: 3px;
  margin-bottom: 7px;
  font-weight: 900; }

.single #ratenzahlung-modal-wrapper .paypal-installment-specific-promotion a {
  display: none; }

.single #ppButton {
  margin-left: 15px;
  margin-top: 15px; }

.single .availability.badge span {
  white-space: normal; }

.single #similar-articles .col-md-3 {
  max-width: 100%;
  flex: 0 0 100%; }

span.articlenumber.small.text-muted {
  display: block; }
  span.articlenumber.small.text-muted span,
  span.articlenumber.small.text-muted b {
    font-size: 14px;
    color: #000; }

.btn-primary:focus, .btn-primary:hover {
  background-color: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%) !important;
  border-color: #006080; }

.btn-primary {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%) !important;
  border-color: #006080; }

.btn {
  box-shadow: rgba(255, 255, 255, 0.2) 0 1px 0 inset, rgba(0, 0, 0, 0.0470588) 0 1px 2px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%);
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  font-size: 16px;
  display: inline-block;
  padding: .5rem 1rem .5rem .5rem; }

.producertag.h6.producer.text-muted {
  display: none; }

.navbar {
  background-color: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%); }

.qty-box {
  border: 1px solid #dcdbd8; }
  .qty-box .qty-input {
    background: transparent;
    color: #000;
    outline: 0;
    height: 100%;
    width: 35px;
    padding: 0;
    /*border: 1px solid #dcdbd8;*/
    border: none;
    border-radius: .1rem 0 0 .1rem;
    border-right: none; }
  .qty-box .qty-btn-container {
    border-left: none;
    background: #fff; }
    .qty-box .qty-btn-container .qty-btn > .qty-sign, .qty-box .qty-btn-container .qty-btn > i {
      border-radius: 43px;
      border: 1px solid #dcdbd8;
      width: 17px;
      color: #7a7f7f; }
    .qty-box .qty-btn-container .qty-btn:not(.disabled):hover {
      background: transparent; }
    .qty-box .qty-btn-container .qty-btn-seperator {
      background: transparent; }

.qty-box .qty-input {
  background: #fff; }

.glyphicon-plus, .glyphicon-minus {
  color: #444 !important; }

.usp.list-unstyled.row li {
  font-size: 18px; }

.mainmenu.pull-lg-right li {
  font-size: 17px;
  padding: .65rem .45rem; }

.fa.fa-minus.qty-sign, .fa.fa-minus {
  color: #7a7f7f !important; }

#subnavigation h3 {
  font-size: 25px;
  line-height: 36px; }

.payment-icons h3 {
  font-size: 25px;
  line-height: 36px; }

.back-to-top.hidden-sm-down {
  background: #000;
  opacity: 1 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease !important;
  -webkit-box-shadow: 0 0 10px 3px #fff;
  box-shadow: 0 0 10px 3px #fff; }

.text-muted.small.add-to-wish-list {
  border: 1px solid #cccc;
  border-radius: 4px;
  padding: .55rem 4.5rem;
  font-size: 16px;
  color: #777 !important;
  width: 243px;
  float: left; }

.tab-content {
  color: #000;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ddd; }

.owl-carousel .carousel-control:hover i {
  color: #77b236; }

.owl-carousel .owl-single-item-control {
  color: #999;
  font-size: 2rem; }

.owl-thumbs .owl-thumb.active, .owl-thumbs .owl-thumb:hover {
  border: 1px solid #000;
  box-shadow: 0 0 5px #333; }

#single-carousel .owl-item img {
  border: 1px solid #ddd; }

.alert-info {
  background-color: #C30101 !important;
  border-color: #C30101 !important;
  color: #fff !important; }

.list-controls-inner {
  background: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%) !important; }
  .list-controls-inner .list-wrapper {
    color: #fff !important; }
    .list-controls-inner .list-wrapper .custom-select {
      padding: 0.1rem 2rem 0.1rem 1rem;
      background-position: calc(100% - 1rem) 0.41rem; }

#CfourCrossselling {
  float: left;
  padding: 0 15px;
  width: 100%; }

#CfourCrossselling .cmp-product-thumb .vat {
  display: none; }

#CfourItemQuestionsForm button {
  color: #fff; }

#add-item-to-basket-overlay #ppButton {
  padding: 0 15px 15px;
  text-align: right; }

.controls-list #ppButton {
  text-align: right;
  margin-right: -0.8rem; }

.single .paypal-installment-specific-promotion {
  border: none;
  padding: 0; }
  .single .paypal-installment-specific-promotion h4 {
    font-size: 1em; }

.top-bar.container {
  padding: 0;
  background-color: #C30101 !important;
  transition: all 0.2s linear 0.2s; }
  .top-bar.container .brand-logo {
    /*@media (min-width:768px) {
      overflow: hidden;
    }*/
    width: 100%;
    background-color: #e9e9e9; }
    .top-bar.container .brand-logo .usp {
      display: inline-flex;
      width: 50%;
      margin: 10px 0;
      vertical-align: middle;
      transform: translateX(50%);
      opacity: 1; }
      .top-bar.container .brand-logo .usp li {
        color: black;
        display: inline-block;
        width: 50%; }
        .top-bar.container .brand-logo .usp li i {
          color: #77b236; }
  .top-bar.container #controlsList a {
    font-size: 15px; }
    .top-bar.container #controlsList a:hover {
      color: #fff; }
  .top-bar.container #controlsList .control-basket .toggle-basket-preview {
    background-color: transparent !important; }
  .top-bar.container #controlsList .control-languages {
    position: absolute;
    left: 150px; }

.top-bar.container * {
  transition: all 0.2s linear 0s; }

.wrapper-main .navbar {
  border-bottom: none !important; }

.wrapper-main .brand-logo img {
  /*position: absolute;*/
  /*top: 70px;*/
  margin-top: -30px; }

.wrapper-main #page-body {
  padding: 0 15px 15px 15px; }

.breadcrumb-item + .breadcrumb-item::before {
  content: "\f101";
  font-family: FontAwesome;
  line-height: 1;
  color: #fff;
  margin: 0 15px; }

.isScrolled .top-bar.container {
  padding: 0;
  background-color: #e9e9e9 !important;
  transition: all 0.2s linear 0s; }
  .isScrolled .top-bar.container .translations {
    opacity: 0;
    transition: all 0.2s linear 0s; }
  .isScrolled .top-bar.container .brand-logo {
    background-color: #e9e9e9;
    height: 22px; }
    .isScrolled .top-bar.container .brand-logo img {
      height: 55px;
      margin-top: -165px; }
    .isScrolled .top-bar.container .brand-logo .usp {
      display: inline-flex;
      width: 50%;
      margin: 10px 0;
      vertical-align: middle;
      transform: translateX(50%);
      opacity: 0; }
  .isScrolled .top-bar.container #controlsList .control-home div, .isScrolled .top-bar.container #controlsList .control-home i {
    color: #555; }
  .isScrolled .top-bar.container #controlsList #login-change i {
    color: #555; }
  .isScrolled .top-bar.container #controlsList #login-change span {
    color: #555; }
  .isScrolled .top-bar.container #controlsList a:hover {
    color: #fff; }
  .isScrolled .top-bar.container #controlsList a span {
    color: #555; }
  .isScrolled .top-bar.container #controlsList a i {
    color: #555; }

.top-bar-header {
  padding: 6px 12px; }
  .top-bar-header .translations {
    margin-top: 2px;
    opacity: 1;
    transition: all 0.2s linear 0s; }
    .top-bar-header .translations i {
      font-size: 26px;
      margin-top: 1px;
      margin-left: 6px; }

.goog-te-gadget-simple .goog-te-menu-value span {
  color: #000; }

.brand-logo .search-box-inner {
  width: 100%; }
  @media (max-width: 767px) {
    .brand-logo .search-box-inner {
      float: right; } }
  .brand-logo .search-box-inner .search-box-shadow-frame {
    padding-left: 0;
    padding-right: 0;
    box-shadow: none; }

@media (max-width: 767px) {
  .translations {
    height: 27px;
    overflow: hidden; }
  .isSwiped .translations {
    height: 0; }
  .isSwiped .usp li {
    display: none !important; }
  .isSwiped .wrapper-main .brand-logo img {
    top: 56px;
    transition: none; }
  .isSwiped .top-bar.container .brand-logo #btnMainMenuToggler {
    top: 60px; } }

.footer {
  background-color: #000;
  padding: 10px 15px 20px;
  color: #fff; }
  .footer .footer-links-payment {
    list-style: none;
    margin: 0; }
    .footer .footer-links-payment li {
      display: inline-block; }
      .footer .footer-links-payment li a {
        color: #fff; }
        .footer .footer-links-payment li a img {
          width: 70px;
          padding-bottom: 10px;
          padding-right: 6px; }
  .footer .footer-links {
    list-style: none;
    margin: 0; }
    .footer .footer-links li a {
      color: #fff; }
      .footer .footer-links li a:hover {
        color: #999; }
  .footer .footer-header {
    color: #fff;
    margin-top: 1rem;
    margin-bottom: 1.25rem; }
  .footer #pe_rating {
    display: inline-block;
    font-size: 16px;
    text-align: center;
    color: #c8b274;
    border: none;
    text-decoration: none;
    outline: none; }
    .footer #pe_rating #pe_rating.pe_g .pe_u {
      display: block;
      margin: 0 0 .3em;
      font-size: .8em;
      line-height: 1.2em; }
    .footer #pe_rating #pe_stars {
      display: inline-block;
      position: relative;
      overflow: hidden;
      width: 5em;
      height: 1.1em;
      margin: 0 !important;
      line-height: 1em;
      font-size: 1em; }
  .footer .text-right {
    text-align: right;
    font-size: 16px; }
  .footer > .row {
    /*border-top: 1px solid #fff;*/
    margin: 15px 0; }
    .footer > .row > div {
      padding: 5px 0 0;
      /* &:last-child {
        padding: 0 !important;
      } */ }
      .footer > .row > div > .row {
        /*border-top: 1px solid #fff;*/
        margin: 15px 0; }
  .footer ul {
    padding: 0; }
    .footer ul li {
      list-style: none; }
      .footer ul li a {
        color: #fff; }
  .footer .footer-links {
    margin: 0; }
    .footer .footer-links a {
      color: #fff; }
  .footer h3 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 900;
    padding: 5px 0px; }
  .footer .footer-payment-bottom {
    display: block;
    text-align: left;
    color: #fff; }
  .footer .to-top {
    text-align: center; }
    .footer .to-top i.fa.fa-arrow-up {
      font-size: 1.5em;
      padding: .75em 0; }
  .footer .btn.text-center.border.mx-auto.rounded-lg.p-0.back-to-top.btn-secondary.pt-1 {
    background: #000;
    opacity: 1 !important;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    -webkit-transition: all 0.3s ease !important;
    transition: all 0.3s ease !important;
    -webkit-box-shadow: 0 0 10px 3px #fff;
    box-shadow: 0 0 10px 3px #fff; }
    .footer .btn.text-center.border.mx-auto.rounded-lg.p-0.back-to-top.btn-secondary.pt-1 i {
      font-size: 29px;
      color: #f7f7f9;
      padding: 0 6px; }
  .footer .btn.btn-secondary.d-block.d-md-none.text-center.p-2 {
    background: -webkit-gradient(linear, left top, left bottom, from(#383838), color-stop(12%, #404040), color-stop(25%, #4a4a4a), color-stop(39%, #333), color-stop(50%, #1f1f1f), color-stop(51%, #000), color-stop(60%, #0d0d0d), color-stop(76%, #1f1f1f), color-stop(91%, #141414), to(#0d0d0d));
    background: linear-gradient(to bottom, #383838 0, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
    -webkit-box-shadow: 0 0 15px 5px #fff;
    box-shadow: 0 0 15px 5px #fff;
    border-color: #000; }
    .footer .btn.btn-secondary.d-block.d-md-none.text-center.p-2 i {
      font-size: 1.5em;
      padding: 0.75em 0; }
  .footer .cookie-bar button {
    border-color: #000 !important; }
  .footer .cookie-bar .btn-appearance {
    background: -webkit-gradient(linear, left top, left bottom, from(#383838), color-stop(12%, #404040), color-stop(25%, #4a4a4a), color-stop(39%, #333), color-stop(50%, #1f1f1f), color-stop(51%, #000), color-stop(60%, #0d0d0d), color-stop(76%, #1f1f1f), color-stop(91%, #141414), to(#0d0d0d));
    background: linear-gradient(to bottom, #383838 0, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
    color: #fff; }
  .footer .cookie-bar .custom-control-appearance .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #000;
    border-color: #000; }
  .footer .cookie-bar .text-appearance {
    color: #000 !important; }
  .footer .widget-grid > .widget-inner {
    padding-left: 0; }

.wrapper-main .navbar {
  background: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%) !important;
  padding: 0; }

.wrapper-main div#mainNavbarCollapsable ul.mainmenu {
  display: inline-flex;
  margin: 0;
  padding-left: 0.5rem; }
  .wrapper-main div#mainNavbarCollapsable ul.mainmenu li ul.collapse {
    min-width: 17em; }

.wrapper-main div#mainNavbarCollapsable li a {
  color: #fff;
  font-weight: bold; }

#subnavigation h3 {
  background: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
  color: #fff;
  margin: 0;
  text-align: center;
  padding: 4px; }

#subnavigation .subnavi {
  list-style: none;
  padding: 0; }
  #subnavigation .subnavi li {
    background: linear-gradient(to bottom, #fcfcfc 0%, #f1f1f1 100%);
    padding: 0; }
    #subnavigation .subnavi li:hover {
      border-color: rgba(0, 142, 189, 0.5); }
      #subnavigation .subnavi li:hover a {
        color: #008ebd; }
    #subnavigation .subnavi li a {
      padding: 6px;
      display: block;
      color: black;
      transition: all .5s;
      font-size: 13px; }

.payment-icons h3 {
  background: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
  color: #fff;
  margin: 0;
  text-align: center; }

.box-gradient-header h3 {
  background: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
  color: #fff;
  margin: 0;
  text-align: center;
  padding: 6px; }

#btnMainMenuToggler {
  color: #000;
  z-index: 999; }
  @media (min-width: 768px) and (max-width: 991px) {
    #btnMainMenuToggler {
      right: 2rem;
      left: auto !important;
      position: absolute;
      top: 5rem; } }

.top-bar .controls-list .control-user {
  margin-left: 0; }
  .top-bar .controls-list .control-user #login-change > .dropdown .dropdown-menu a:hover {
    background-color: #C30101; }

.top-bar .controls-list .control-home div {
  color: #fff;
  font-size: 15px; }
  .top-bar .controls-list .control-home div i {
    color: #fff;
    font-size: 15px;
    padding-right: 2px; }

.cmp-address-list .item input:checked + .item-inner {
  color: #000;
  background-color: #9994;
  box-shadow: inset 0 0 0 .1rem #777; }

.page-item.active .page-link {
  background-color: transparent;
  border: 2px solid #fff; }

.page-item.disabled .page-link {
  background-color: transparent;
  border: 2px solid #000; }

.page-item .page-link {
  background-color: transparent;
  border: 2px solid #000;
  height: 32px;
  color: #000; }
  .page-item .page-link i {
    color: #000; }

.navDropdown2 {
  background-color: #F1F1F1 !important; }
  .navDropdown2 .level1 a {
    color: #000 !important; }
    .navDropdown2 .level1 a:hover {
      color: #000 !important; }

@media (min-width: 992px) {
  nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu li ul.collapse {
    min-height: 0; }
  nav.navbar.megamenu ul.mainmenu li.level1 {
    padding: .3em .8em !important;
    border: 1px solid transparent; }
    nav.navbar.megamenu ul.mainmenu li.level1 a {
      color: #000 !important; } }

ul.mainmenu > li {
  position: relative !important; }
  ul.mainmenu > li:hover {
    background: transparent !important; }

.ddown:hover {
  background: transparent !important; }

@media (max-width: 767px) {
  .wrapper-main .brand-logo img {
    top: 65px;
    height: 50px;
    left: 50%;
    margin-left: -64px; }
  .wrapper-main .brand-logo .navbar-toggler {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%); }
  #page-body.main {
    margin-top: 0; }
    #page-body.main .breadcrumb {
      font-size: 1rem;
      padding: 0;
      background: transparent; }
      #page-body.main .breadcrumb .breadcrumb-item:before {
        margin: 0; } }

.mainmenu .ddown a::after {
  content: "" !important; }

#navigation-links {
  background: #fff; }
  #navigation-links li {
    padding: 10px 0; }
    #navigation-links li a {
      color: #000;
      font-weight: bold; }

nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu li.ddown ul.collapse.navDropdown {
  background-color: #F1F1F1 !important;
  padding: 0;
  margin-top: 9px; }
  nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu li.ddown ul.collapse.navDropdown li {
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #F1F1F1 !important; }
    nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu li.ddown ul.collapse.navDropdown li:hover {
      background: linear-gradient(to bottom, #EAEAEA 0%, #D7D7D7 100%) !important;
      /*a{
                background: $cfourNavBackgroundHover !important;
              }*/ }
    nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu li.ddown ul.collapse.navDropdown li a {
      color: #444 !important;
      text-align: left;
      /*background-color: $cfourNavBackground !important;*/
      width: 100%;
      /*border-bottom: 1px solid #ddd;*/
      font-weight: normal;
      font-size: 16px; }

.basket-small ul .item-property-value > strong:after {
  content: none !important; }

.search-input {
  /* margin-left: 8px;*/
  height: 30px;
  width: 225px;
  border-radius: 4px 0 0 4px;
  border: 0 none;
  padding: 8px; }

.search-submit {
  margin-left: -6px;
  border: 0 none;
  width: 34px;
  height: 30px;
  border-left: 1px solid #e5e5e5;
  position: relative;
  top: -1px; }
  .search-submit i {
    font-size: 17px; }

.search-box-inner {
  display: inline-flex;
  padding: .5rem; }

.autocomplete-suggestion {
  background: #F1F1F1;
  padding: 5px 15px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  color: #000;
  width: 100%; }
  .autocomplete-suggestion:hover {
    background: linear-gradient(to bottom, #EAEAEA 0, #D7D7D7 100%) !important; }

.autocomplete-suggestion img {
  max-width: 45px;
  max-height: 45px;
  margin-right: 10px; }

.preview-img {
  width: 55px;
  margin-right: 5px;
  text-align: center; }

.breadcrumbs {
  background-color: transparent; }

.modal-dialog .modal-footer .btn.btn-primary.btn-medium {
  background: #000; }

.modal-dialog .modal-footer .reset-pwd-direction {
  margin-left: 15px;
  margin-top: 5px; }
  .modal-dialog .modal-footer .reset-pwd-direction a {
    color: #000;
    font-size: 15px; }
  .modal-dialog .modal-footer .reset-pwd-direction a:hover {
    color: #000; }

.modal-dialog .modal-content {
  background: #E9E9E9; }
  .modal-dialog .modal-content .modal-header .close {
    opacity: 1; }

#vue-app .btn-primary {
  background: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
  color: #fff; }

#vue-app .input-unit {
  border-radius: 4px;
  border: 1px solid #dcdbd8; }
  #vue-app .input-unit .input-unit-label,
  #vue-app .input-unit label {
    text-transform: none;
    font-weight: 500; }

#vue-app input.search-input {
  color: #000; }

.page-content .btn-danger {
  background: #ff0000; }

.nav-tabs .nav-link.active {
  color: #fff; }

.nav-tabs .nav-link.active:hover {
  color: #fff; }

.badge, .tag {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .1rem;
  -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out; }

.page-category .list-controls .pagination {
  float: right; }
  .page-category .list-controls .pagination li {
    background: #fff; }
    .page-category .list-controls .pagination li.active {
      background: transparent; }

.warning-block {
  color: #000; }

.autocomplete-suggestions {
  max-height: 80vh;
  height: 30vh;
  margin-top: 11px;
  position: absolute;
  z-index: 999; }
  .autocomplete-suggestions .text-truncate {
    white-space: normal; }
  .autocomplete-suggestions img {
    margin-left: 0 !important; }

.back-to-top i {
  font-size: 29px;
  color: #f7f7f9;
  padding: 4px 6px; }

.basket-preview-footer a[title="Warenkorb"] {
  border: 1px solid #000;
  color: #000; }
  .basket-preview-footer a[title="Warenkorb"]:hover {
    background: linear-gradient(to bottom, #383838 0, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
    color: #fff !important; }
    .basket-preview-footer a[title="Warenkorb"]:hover i {
      color: #fff; }

.top-bar.container #controlsList .basket-preview-wrapper a {
  color: #000; }
  .top-bar.container #controlsList .basket-preview-wrapper a[title="Kasse"] {
    color: #fff;
    max-height: 35px; }
  .top-bar.container #controlsList .basket-preview-wrapper a.item-name {
    float: left;
    padding: 0; }

.basket-preview-wrapper {
  color: #000;
  width: 100vw; }
  .basket-preview-wrapper .img-basket-small {
    display: block;
    max-width: 100%;
    max-height: 100%; }
  .basket-preview-wrapper .qty-box .qty-btn-container {
    width: 25px;
    position: relative;
    /*background: #f4f4f4;*/
    background: #fff;
    /*border: 1px solid #dcdbd8;*/
    border: none;
    border-left: none;
    border-radius: 0 .1rem .1rem 0;
    overflow: hidden;
    height: 100%; }
    .basket-preview-wrapper .qty-box .qty-btn-container .qty-box {
      margin: 0 0 5px auto;
      height: 41px; }
  .basket-preview-wrapper .item-remove-container {
    font-size: 1.2em;
    line-height: 1.2em;
    text-align: right; }
    .basket-preview-wrapper .item-remove-container > .item-remove-button {
      cursor: pointer;
      padding: 0;
      background: none;
      color: #d9534f;
      border: none; }
  .basket-preview-wrapper .basket-list-item {
    padding: 1.5rem 0; }
    .basket-preview-wrapper .basket-list-item .basket-item.component-loading {
      display: flex; }
  .basket-preview-wrapper .meta-container a {
    /*text-transform: uppercase;*/
    font-weight: 600; }
  .basket-preview-wrapper .basket-preview-hover-wrapper {
    position: absolute;
    width: 100vw;
    right: 0;
    z-index: 99999; }
  .basket-preview-wrapper .basket-preview-content .item-list {
    min-height: auto;
    padding: 0 !important;
    max-width: 100%;
    /*padding: 1.5rem;*/ }
  .basket-preview-wrapper .basket-preview-content.basket-preview-hover {
    max-height: 90vh;
    padding: 1.5rem;
    flex-direction: column;
    background: #f8f9fa; }
  .basket-preview-wrapper .basket-totals {
    /*padding: 1.5rem;*/ }

.qty-btn-container .qty-btn {
  border: none;
  background-color: transparent; }

.similar_article .feedback-category-view {
  display: none; }

.basket .basket-item a, .checkout .basket-item a {
  color: #000 !important; }
  .basket .basket-item a:hover, .checkout .basket-item a:hover {
    color: #000 !important; }

.page-login .main > div > div > div {
  background: #fff; }

.page-login .login-view-title {
  margin-top: 10px; }

.page-content.basket, .page-content.checkout, .page-content.contact, .page-content.wishlist, .page-content.myaccount {
  margin-top: 30px; }

.categoriegrid.page-content.container-max {
  background: none; }
  .categoriegrid.page-content.container-max .category-title {
    font-size: 27px;
    color: #000; }
  .categoriegrid.page-content.container-max .category-description {
    color: #000; }

@media (max-width: 767px) {
  .page-category .page-content {
    margin-top: 35px; } }

@media (max-width: 439px) {
  .page-category .page-content {
    margin-top: 55px; } }

@media (max-width: 399px) {
  .page-category .page-content {
    margin-top: 80px; } }

.cmp-product-thumb .add-to-basket-lg-container {
  border-color: transparent #000 transparent transparent;
  color: #fff; }
  .cmp-product-thumb .add-to-basket-lg-container:hover {
    border-color: transparent #77b236 transparent transparent; }
    .cmp-product-thumb .add-to-basket-lg-container:hover > i {
      color: #fff; }

.special-tags .bg-primary {
  background-color: #77b236 !important;
  text-transform: uppercase;
  font-weight: 900 !important; }

.list-controls .list-itemsperpage, .list-controls .list-sort {
  display: inline-flex;
  white-space: nowrap;
  align-items: baseline;
  padding: 0 10px; }
  .list-controls .list-itemsperpage label, .list-controls .list-sort label {
    margin-right: 1em; }

html .wrapper-main .breadcrumb {
  font-size: 16px;
  background: transparent; }
  @media (min-width: 768px) {
    html .wrapper-main .breadcrumb {
      padding-top: 20px !important; } }
  html .wrapper-main .breadcrumb .breadcrumb-item {
    color: #fff; }
    html .wrapper-main .breadcrumb .breadcrumb-item a {
      color: #fff; }

.navbar-brand img {
  max-height: 5rem;
  margin-bottom: -36px;
  margin-left: 32px; }

.details-button {
  position: relative;
  background: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
  text-align: center;
  display: inline-block;
  width: 164px;
  left: 50%;
  margin-left: -87px;
  margin-bottom: 8px; }
  .details-button a {
    color: #fff;
    font-weight: bold; }
    .details-button a:hover {
      color: #fff;
      font-weight: bold; }

.categorybox {
  background: linear-gradient(to bottom, #fcfcfc 0%, #f1f1f1 100%);
  border: 1px solid black;
  padding: 10px;
  margin-bottom: 15px; }

/*#CfourTileListing {*/
.square-container {
  width: 100%;
  display: block;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden; }

/*}*/
.faq .faq-section .faq-item {
  margin-bottom: 10px; }

.faq .faq-section .faq-question {
  border-radius: 5px;
  background: #555;
  color: #fff;
  padding: 10px 10px 10px 35px;
  position: relative;
  cursor: pointer; }

.faq .faq-section .faq-question.open-faq {
  border-radius: 5px 5px 0 0; }

.faq .faq-section .faq-question.open-faq + .faq-answer {
  height: auto;
  -webkit-transition: all .25s;
  transition: all .25s;
  display: block; }

.faq .faq-section .faq-question.open-faq + .faq-answer {
  height: auto;
  -webkit-transition: all .25s;
  transition: all .25s;
  display: block; }

.faq .faq-section .faq-question.open-faq .fas.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  top: 9px; }

.faq .faq-section .faq-answer {
  border: 1px solid #555;
  padding: 10px 10px 0;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  height: 0;
  display: none; }

.faq .faq-section .faq-question .fas.fa-chevron-down {
  position: absolute;
  top: 13px;
  left: 12px;
  z-index: 1;
  -webkit-transition: all .25s;
  transition: all .25s;
  font-family: FontAwesome; }

.faq .faq-summary {
  list-style: none;
  padding: 0;
  margin-bottom: 20px; }
  .faq .faq-summary li {
    border: 1px solid #555;
    border-bottom: none; }
  .faq .faq-summary li:last-child {
    border-bottom: 1px solid #555; }
  .faq .faq-summary label {
    padding: 10px;
    background: #e9e9e9;
    display: block;
    cursor: pointer; }
  .faq .faq-summary label.active {
    background: #555;
    color: #fff;
    -webkit-transition: all .25s;
    transition: all .25s;
    margin-bottom: 0; }

@media (min-width: 992px) and (max-width: 1199px) {
  .top-bar.container .brand-logo .usp {
    width: 52%; } }

@media (min-width: 768px) {
  html .autocomplete-suggestions {
    width: 100% !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  html .wrapper-main .breadcrumb {
    padding-top: 40px !important; }
  .top-bar-header .translations {
    padding-left: 30px;
    margin-top: 10px;
    margin-bottom: 15px; }
  .navbar-brand img {
    margin-left: 12px; }
  .top-bar.container .brand-logo img {
    margin-top: -5px; }
  .top-bar.container .brand-logo .usp {
    display: inline-flex;
    width: 62%;
    margin-left: -30%; }
  .isScrolled .brand-logo img {
    margin-top: -115px !important; }
  .payment-icons h3 {
    font-size: 20px; }
  .text-muted.small.add-to-wish-list {
    float: left;
    width: 262px; }
  span.price.h1 {
    float: none; }
  hr {
    border: 1px solid transparent; }
  .add-to-basket-container .quantity-input-container {
    margin-right: 15px; }
  .single-rightside .btn-block {
    width: 187px; }
  .breadcrumb {
    padding-top: 0;
    margin-top: -35px !important; } }

@media (max-width: 991px) {
  .top-bar .controls-list > li a:not(.btn) {
    color: #fff; }
  .basket-preview-hover .basketBtn:hover i,
  .basket-preview-hover .checkOutBtn:hover i {
    color: #fff; }
  .btn-outline-primary {
    border-color: #000;
    color: #000;
    margin-bottom: 20px; }
    .btn-outline-primary:hover {
      background: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
      border-color: #000; }
  #subnavigation {
    display: none; }
  .box-gradient-header h3 {
    margin-top: 25px; } }

@media (max-width: 991px) and (min-width: 768px) {
  .navbar-brand {
    padding-top: 1rem;
    padding-bottom: 3.5rem; } }

@media (max-width: 991px) {
  .navbar-brand img {
    margin-left: 25px; }
  .mobile-navigation {
    background: #000; }
    .mobile-navigation ul.breadcrumb {
      background: #000;
      border-bottom: 1px solid #fff;
      margin-right: 15px;
      margin-left: 15px; }
  .wrapper-main .navbar .mainmenu li a:last-child {
    margin-right: 20px;
    margin-left: 20px;
    border-bottom: 1px solid #fff; }
  .btn-outline-primary:active:focus, .btn-outline-primary:active:hover {
    color: #fff;
    background-color: #77b236;
    border-color: #77b236; } }

@media (max-width: 767px) {
  .top-bar.container #controlsList {
    justify-content: flex-end; }
    .top-bar.container #controlsList .control-user {
      flex: 0;
      display: contents; }
    .top-bar.container #controlsList li {
      display: inline-flex !important; }
  .top-bar.container .brand-logo .navbar-brand {
    margin-left: 10%; }
  .top-bar.container .brand-logo .usp {
    /*width: 100%;
        margin-top: 52px;*/
    transform: translateX(0%); }
    .top-bar.container .brand-logo .usp li {
      font-size: 15px; }
  .top-bar.container .brand-logo #btnMainMenuToggler {
    /*border: 1px solid #ccc;*/
    border-radius: 10%;
    /* color: #888;*/
    position: absolute;
    /* top: 30px;*/
    margin: 0 !important;
    height: 35px;
    right: 1rem !important;
    left: auto; }
  .footer .to-top {
    background: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
    -webkit-box-shadow: 0 0 15px 5px #fff;
    box-shadow: 0 0 15px 5px #fff; }
  .footer .footer-payment-bottom,
  .footer .text-right {
    text-align: left;
    font-size: 12px; } }

@media (max-width: 767px) {
  .text-muted.small.add-to-wish-list {
    padding: .55rem 0;
    float: left;
    width: 260px;
    text-align: center; }
  .add-to-basket-container {
    width: 260px; }
  .navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .1rem; }
  .search-input {
    margin-left: 0;
    flex-grow: 1;
    flex: 0 0 85%;
    max-width: 80%;
    width: 100vw; } }

@media (max-width: 543px) {
  .navbar-brand img {
    margin-left: 20px; }
  .wrapper-main .brand-logo img {
    top: 90px; }
  .cmp-product-thumb .thumb-image,
  .cmp-product-thumb .thumb-content {
    width: 100%; }
  .cmp-product-thumb .thumb-content {
    margin: 0;
    padding: 0; }
    .cmp-product-thumb .thumb-content .thumb-title.small {
      margin-top: 20px; }
  .isScrolled .top-bar.container .brand-logo img {
    top: 62px; }
  span.price.h1 {
    font-size: 37px; }
  #btnMainMenuToggler {
    margin-top: 20px; }
  .list-controls .list-sort {
    padding: 0 0 0 10px; }
  .footer hr {
    border-top: none; }
  .footer .row {
    border-top: none; }
  .footer .footer-links-payment li a img {
    width: 69px; } }

@media (max-width: 480px) {
  .list-controls .list-sort {
    padding: .5em; } }

@media (max-width: 768px) {
  .top-bar {
    position: relative; } }

@media (min-width: 544px) {
  .brand-logo .usp {
    transform: translateX(10%); } }

.modal-open[style],
#page-header[style] {
  padding-right: 0 !important; }

.m-y-1 > .col-4, .my-1 > .col-4 {
  float: left; }

.m-y-1 > .col-12, .my-1 > .col-12 {
  clear: both; }

.widget.widget-image-box.widget-image-box-no-caption.widget-primary.widget-proportional {
  padding: 0; }

.widget.widget-image-box.widget-image-box-no-caption.widget-primary.widget-proportional img {
  position: relative;
  transform: none;
  left: auto; }

.home .payment-icons .footer-links-payment {
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.feedback-stars-average.feedback-category-view {
  margin: 0 auto; }

@media (min-width: 768px) and (max-width: 991px) {
  .search-box-inner {
    position: relative;
    left: 1.5rem;
    width: 100vw !important;
    margin: 6px 0; }
    .search-box-inner .search-box-shadow-frame {
      width: 100% !important; }
      .search-box-inner .search-box-shadow-frame .search-input {
        width: 91%; }
  .isScrolled .search-box-inner {
    position: relative;
    top: -85px; }
  .isScrolled #btnMainMenuToggler {
    top: 20px; }
  .usp.list-unstyled.row li {
    font-size: 10px;
    max-width: 33.33% !important; } }

@media (max-width: 767px) {
  .brand-logo {
    position: relative; }
    .brand-logo img {
      margin-top: 10px !important; }
    .brand-logo .usp {
      flex: 0 0 80%;
      max-width: 80%;
      width: 100% !important; }
  .navbar-brand {
    padding: 0; }
  .brand-logo * {
    transition: all 0.0s linear 0s !important; }
  .isSwiped .top-bar.container .brand-logo #btnMainMenuToggler {
    top: 30px; }
  .search-box-inner {
    position: absolute;
    top: -133px;
    left: 250px;
    padding: 10px 17px; }
  .isSwiped .search-box-inner {
    position: absolute;
    top: -60px;
    left: 185px; } }
  @media (max-width: 767px) and (max-width: 575px) {
    .isSwiped .search-box-inner {
      top: -200px; } }

@media (max-width: 767px) {
  .isSwiped .top-bar.container .brand-logo {
    padding-top: 8px; }
  .brand-logo > a > img {
    margin: 5px 0 0 0 !important;
    transition: all 0.1s linear 0s !important; }
  .isSwiped .wrapper-main .brand-logo > a > img {
    top: 5px !important;
    width: 103px !important;
    height: auto !important; }
  input.search-input {
    width: 100px; }
  /*.brand-logo .search-box-inner {
    position: absolute;
    top:0;
  }*/ }

.modal-footer .btn.btn-outline-primary.btn-medium.mr-2 {
  margin-bottom: 0; }

.add-to-basket-container .col-12.col-sm-6.col-md-12 {
  width: 100%;
  flex: 1 1 100%;
  max-width: 100%; }

.CfourQuantityInput {
  max-width: 100%; }

.add-to-basket-container {
  width: auto; }

.search-box-shadow-frame .autocomplete-suggestions {
  right: 0;
  width: 90vw; }

@media (min-width: 992px) {
  .search-box-shadow-frame .autocomplete-suggestions .autocomplete-image {
    transform: none;
    position: static !important; } }

@media (max-width: 991px) {
  .search-box-shadow-frame .autocomplete-suggestions .autocomplete-image {
    position: unset !important;
    top: 0 !important;
    margin-left: 0; } }

@media (max-width: 575px) {
  .brand-logo .usp {
    max-width: 100%; }
    .brand-logo .usp li {
      font-size: 12px !important;
      padding: 0 0px 0 7px; }
  .brand-logo .navbar-brand {
    margin-left: 55px !important; }
  .search-box-inner {
    top: -123px;
    left: 175px;
    width: 100%; }
    .search-box-inner .search-input {
      max-width: 80%;
      width: 32vw; }
  .isSwiped .search-box-inner {
    top: -65px;
    left: 162px; }
  .isSwiped #btnMainMenuToggler {
    top: 33px !important; }
  #btnMainMenuToggler {
    left: 9px; } }

.parallax-img-container .parallax-text-container h1 {
  margin-top: 50px; }

.list-controls .list-controls-inner {
  background-color: #E5E5E5;
  padding: .5rem; }

.Cfourhidden {
  display: none; }

#CfourListingSwitch {
  margin-bottom: 20px; }
  #CfourListingSwitch form {
    display: inline-block; }
  #CfourListingSwitch .switchButton {
    border: none;
    background: transparent;
    color: #000;
    font-size: 25px; }
    #CfourListingSwitch .switchButton:hover, #CfourListingSwitch .switchButton.active {
      color: #E5E5E5; }

.cmp-product-thumb {
  border: 1px solid #000; }
  .cmp-product-thumb .thumb-background, .cmp-product-thumb .main-image, .cmp-product-thumb .thumb-content {
    background-color: transparent; }
  .cmp-product-thumb .add-to-basket-lg-container > i {
    color: #fff; }
  .cmp-product-thumb .add-to-basket-lg-container:hover {
    border-color: transparent #E5E5E5 transparent transparent; }
    .cmp-product-thumb .add-to-basket-lg-container:hover > i {
      color: #fff; }
  .cmp-product-thumb .crossprice {
    color: #E5E5E5;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.1; }
  .cmp-product-thumb .price {
    color: #77b236;
    font-weight: 900;
    font-size: 20px;
    text-align: right; }
  .cmp-product-thumb .price-col {
    padding: 0;
    left: 50%; }
  .cmp-product-thumb .price-view-port-col {
    padding: 0;
    text-align: right; }
    .cmp-product-thumb .price-view-port-col + .price-col {
      left: 0; }
  .cmp-product-thumb .vat-porto-info, .cmp-product-thumb .vat {
    text-align: right;
    display: inline-block;
    width: 100%;
    margin-top: 6px;
    color: #c40203 !important; }
    .cmp-product-thumb .vat-porto-info > a, .cmp-product-thumb .vat > a {
      color: #c40203;
      text-decoration: underline; }
  .cmp-product-thumb .special-tags {
    padding: 18px; }
    .cmp-product-thumb .special-tags .special-tag, .cmp-product-thumb .special-tags .tag {
      border-radius: 5px;
      padding: 5px;
      box-shadow: none;
      position: relative; }
      .cmp-product-thumb .special-tags .special-tag.bg-danger, .cmp-product-thumb .special-tags .tag.bg-danger {
        background-color: #FFCBFF !important;
        color: #c40203 !important; }
        .cmp-product-thumb .special-tags .special-tag.bg-danger > i, .cmp-product-thumb .special-tags .tag.bg-danger > i {
          padding: 5px 7px; }
        .cmp-product-thumb .special-tags .special-tag.bg-danger::after, .cmp-product-thumb .special-tags .tag.bg-danger::after {
          border-color: transparent #ffcbff transparent; }
      .cmp-product-thumb .special-tags .special-tag.bg-primary, .cmp-product-thumb .special-tags .tag.bg-primary {
        background-color: #C4DCFE !important;
        color: #fff !important; }
        .cmp-product-thumb .special-tags .special-tag.bg-primary > i, .cmp-product-thumb .special-tags .tag.bg-primary > i {
          padding: 5px 6px; }
        .cmp-product-thumb .special-tags .special-tag.bg-primary::after, .cmp-product-thumb .special-tags .tag.bg-primary::after {
          border-color: transparent #C4DCFE transparent; }
      .cmp-product-thumb .special-tags .special-tag.bg-success, .cmp-product-thumb .special-tags .tag.bg-success {
        background-color: #30f292 !important;
        color: #fff !important; }
        .cmp-product-thumb .special-tags .special-tag.bg-success > i, .cmp-product-thumb .special-tags .tag.bg-success > i {
          padding: 2px 12px;
          font-size: 31px; }
        .cmp-product-thumb .special-tags .special-tag.bg-success::after, .cmp-product-thumb .special-tags .tag.bg-success::after {
          border-color: transparent #30f292 transparent; }
      .cmp-product-thumb .special-tags .special-tag > i, .cmp-product-thumb .special-tags .tag > i {
        font-size: 25px; }
      .cmp-product-thumb .special-tags .special-tag::after, .cmp-product-thumb .special-tags .tag::after {
        content: "";
        display: block;
        position: absolute;
        right: 8px;
        bottom: -12px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 12px 0; }
  .cmp-product-thumb .thumb-title {
    font-size: 16px;
    text-transform: uppercase;
    color: #c40203; }
    .cmp-product-thumb .thumb-title > small {
      text-transform: none;
      font-size: 100%;
      line-height: 30px;
      display: inline-block;
      margin-bottom: 10px; }
  @media (max-width: 1199px) {
    .cmp-product-thumb .price, .cmp-product-thumb .crossprice {
      font-size: 16px; } }
  @media (max-width: 991px) {
    .cmp-product-thumb .mobile-icon-right {
      float: none; } }
  @media (max-width: 575px) {
    .cmp-product-thumb .price, .cmp-product-thumb .crossprice {
      font-size: 14px;
      min-height: unset;
      line-height: 20px; } }
  @media (max-width: 543px) {
    .cmp-product-thumb .special-tags {
      padding: 0; }
      .cmp-product-thumb .special-tags .tag {
        padding: 0; }
        .cmp-product-thumb .special-tags .tag > i {
          font-size: 18px; }
        .cmp-product-thumb .special-tags .tag::after {
          right: 6px;
          bottom: -7px;
          border-width: 0 10px 7px 0; }
        .cmp-product-thumb .special-tags .tag.bg-success > i {
          padding: 4px 11px;
          font-size: 20px; } }

@media (max-width: 400px) {
  .thumb-content, .thumb-image {
    width: 100%; }
  .CfourAddToBasket .btn-outline-primary i {
    display: inline-block; }
  .CfourAddToBasket .btn-outline-primary .mobile-text-only {
    display: none; } }

#CfourDetailedListing #vue-rendered-item-list {
  display: block !important; }
  #CfourDetailedListing #vue-rendered-item-list .CfourCategoryItem {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
    width: 100%; }

#CfourDetailedListing .tilesView {
  display: none; }

#CfourDetailedListing .product-list li {
  display: block;
  padding: 0 .5rem; }

#CfourDetailedListing .cmp-product-thumb {
  border: none;
  padding-bottom: 15px;
  width: auto;
  float: none; }
  #CfourDetailedListing .cmp-product-thumb .CfourArticleTable {
    width: 100%; }
  #CfourDetailedListing .cmp-product-thumb .availability.tag {
    white-space: normal; }
  #CfourDetailedListing .cmp-product-thumb .CfourListingButtons {
    margin-top: 30px; }
    #CfourDetailedListing .cmp-product-thumb .CfourListingButtons .category-list-view-port {
      display: block; }
    #CfourDetailedListing .cmp-product-thumb .CfourListingButtons .CfourAddToBasket .category-list-view-port .add-to-basket-lg-container {
      background-color: #000;
      border: none;
      position: relative;
      height: auto;
      margin-bottom: 15px;
      width: 100%; }
      #CfourDetailedListing .cmp-product-thumb .CfourListingButtons .CfourAddToBasket .category-list-view-port .add-to-basket-lg-container > * {
        display: block;
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        margin: 0; }
      #CfourDetailedListing .cmp-product-thumb .CfourListingButtons .CfourAddToBasket .category-list-view-port .add-to-basket-lg-container:hover {
        border-color: transparent #E5E5E5 transparent transparent; }
        #CfourDetailedListing .cmp-product-thumb .CfourListingButtons .CfourAddToBasket .category-list-view-port .add-to-basket-lg-container:hover > i {
          color: #c40203; }
    #CfourDetailedListing .cmp-product-thumb .CfourListingButtons .CfourAddToBasket .add-to-basket-lg-container {
      border: none;
      display: table;
      position: relative;
      height: 38px;
      margin-bottom: 15px;
      width: 100%; }
      #CfourDetailedListing .cmp-product-thumb .CfourListingButtons .CfourAddToBasket .add-to-basket-lg-container > * {
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        position: static;
        text-align: center;
        vertical-align: middle;
        display: table-cell; }
      #CfourDetailedListing .cmp-product-thumb .CfourListingButtons .CfourAddToBasket .add-to-basket-lg-container:hover {
        border-color: transparent #E5E5E5 transparent transparent; }
        #CfourDetailedListing .cmp-product-thumb .CfourListingButtons .CfourAddToBasket .add-to-basket-lg-container:hover > i {
          color: #c40203; }
    #CfourDetailedListing .cmp-product-thumb .CfourListingButtons .CfourAddToBasket .btn-group {
      opacity: 1;
      width: 100%;
      position: static;
      margin-bottom: 10px; }
      #CfourDetailedListing .cmp-product-thumb .CfourListingButtons .CfourAddToBasket .btn-group button {
        background-color: #E5E5E5;
        width: 100%;
        border: none; }
    #CfourDetailedListing .cmp-product-thumb .CfourListingButtons .CfourListingMoreButton {
      background-color: #000;
      border: none;
      display: table;
      position: relative;
      height: 38px;
      width: 200px; }
      #CfourDetailedListing .cmp-product-thumb .CfourListingButtons .CfourListingMoreButton > * {
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        position: static;
        text-align: center;
        vertical-align: middle;
        display: table-cell; }
  #CfourDetailedListing .cmp-product-thumb .thumb-background, #CfourDetailedListing .cmp-product-thumb .main-image, #CfourDetailedListing .cmp-product-thumb .thumb-content {
    background-color: transparent; }
  #CfourDetailedListing .cmp-product-thumb .producerimage img {
    width: 100%; }
  #CfourDetailedListing .cmp-product-thumb .crossprice {
    color: #E5E5E5;
    font-size: 24px;
    font-weight: normal; }
  #CfourDetailedListing .cmp-product-thumb .price {
    color: #77b236;
    font-weight: 700;
    font-size: 26px;
    text-align: left; }
  #CfourDetailedListing .cmp-product-thumb .vat-porto-info, #CfourDetailedListing .cmp-product-thumb .vat {
    text-align: left;
    margin-top: 6px;
    margin-bottom: 20px; }

@media (max-width: 1033px) {
  #CfourDetailedListing .price {
    font-size: 30px !important; } }

@media (max-width: 767px) {
  #CfourDetailedListing .cmp-product-thumb .thumb-image {
    width: 100%; }
  #CfourDetailedListing .thumb-content {
    width: auto; }
  #CfourDetailedListing .crossprice {
    font-size: 14px !important; }
  #CfourDetailedListing .price {
    font-size: 18px !important; } }

@media (max-width: 543px) {
  #CfourDetailedListing .thumb-content {
    margin-top: 20px; } }

@media (min-width: 768px) {
  #CfourDetailedListing .Cfour-visible-xs {
    display: none; } }

#CfourDetailedListing .feedback-stars-average.feedback-category-view {
  float: left; }

#CfourTileListing #vue-rendered-item-list {
  display: block !important; }

#CfourTileListing .CfourCategoryItem {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 100%; }

@media (min-width: 544px) {
  #CfourTileListing .CfourCategoryItem {
    width: 50%; } }

@media (min-width: 768px) {
  #CfourTileListing .CfourCategoryItem {
    width: 33.33333%; } }

@media (min-width: 992px) {
  #CfourTileListing .CfourCategoryItem {
    width: 25%; } }

#CfourTileListing .detailedView {
  display: none; }

@media (max-width: 991px) {
  .cmp-product-thumb .thumb-content {
    margin-top: 15px; }
  .cmp-product-thumb .special-tags .tag {
    padding: 5px !important; }
  .cmp-product-thumb .add-to-basket-lg-container {
    border: 1px solid #014FA4 !important;
    padding: 6px 10px;
    position: relative;
    width: 100%;
    border-radius: 2px;
    color: #014FA4;
    background: #FFFFFF;
    margin-top: 10px; }
    .cmp-product-thumb .add-to-basket-lg-container p {
      margin-bottom: 0; }
    .cmp-product-thumb .add-to-basket-lg-container:hover, .cmp-product-thumb .add-to-basket-lg-container:focus {
      border-color: #014fa4; }
  .cmp-product-thumb a {
    color: #014fa4; } }

.list-controls-top .pagination {
  justify-content: right;
  margin-top: -4.25rem; }

.page-content {
  background-color: #fff; }

.custom-texts {
  background: #e9e9e9; }
  .custom-texts:first-child {
    margin-bottom: 16px;
    margin-top: 20px; }

.home #subnavigation {
  margin-top: 20px; }

.home .custom-texts p a {
  color: #000;
  text-decoration: underline; }

.btn-danger {
  background-color: #c30101 !important; }

@media (max-width: 768px) {
  #page-body.main {
    margin-top: 0; } }

/* fixing Ceres 3.0.2 */
.wrapper-main header .container-max > .row {
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: -15px;
  margin-right: -15px; }

nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu > li > ul.collapse {
  -webkit-column-count: 1;
  column-count: 1; }

html .top-bar .controls-list .control-user {
  flex: none; }

html .list-inline-item {
  justify-content: flex-end;
  display: inline-flex; }

html .wrapper-main header .controls-list {
  justify-content: flex-end;
  margin-bottom: 0;
  float: right; }

html #CfourDetailedListing .cmp-product-thumb .prices {
  justify-content: left; }

html .alert {
  width: 100%; }

html .similar-articles .owl-item {
  width: 100% !important; }

html .registration .col-sm-10.offset-sm-1.col-md-6.offset-md-3 {
  background: #fff;
  padding: 15px; }

html .basket .bg-white {
  background-color: transparent !important; }

html .basket #ppButton {
  float: right;
  margin-top: 15px;
  margin-bottom: 25px; }

html .btn.btn-link.filter-toggle {
  display: none; }

html .cmp-paginator .page-link {
  background: #fff;
  color: #000; }
  html .cmp-paginator .page-link:hover {
    color: #000; }

html .cmp-paginator .disabled {
  background: #fff; }

.basket-item-container .basket-item .meta-container-wrapper .meta-container-wrapper-inner .meta-container .item-name {
  color: #000 !important; }

.categoriegrid.page-content .cmp-paginator {
  float: right; }

.page-confirmation .page-content {
  background: linear-gradient(to bottom, #f2f2f2 0%, #E5E5E5 100%) !important;
  padding-bottom: 0 !important; }
  .page-confirmation .page-content .confirmation-button-panel a, .page-confirmation .page-content .confirmation-button-panel .invoice {
    background: linear-gradient(to bottom, #383838 0, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
    border: none; }
  .page-confirmation .page-content .text-appearance {
    color: #000 !important; }
  .page-confirmation .page-content .widget-feedback-orderconfirmation {
    background: #fff;
    border: 1px solid #dee2e6; }
    .page-confirmation .page-content .widget-feedback-orderconfirmation .feedback-container.feedback-orderitem-container {
      text-align: center;
      margin: auto;
      padding: 0 15px; }
      .page-confirmation .page-content .widget-feedback-orderconfirmation .feedback-container.feedback-orderitem-container .title {
        font-size: 1.5rem; }
      .page-confirmation .page-content .widget-feedback-orderconfirmation .feedback-container.feedback-orderitem-container .feedback-item-link a {
        font-size: 100%;
        margin-top: 10px;
        background: #fff; }
    .page-confirmation .page-content .widget-feedback-orderconfirmation a {
      background: linear-gradient(to bottom, #383838 0, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
      border: none; }
  .page-confirmation .page-content .widget-order-data, .page-confirmation .page-content .widget-purchased-items, .page-confirmation .page-content .widget-order-totals {
    background: #fff;
    font-size: 80%; }
  .page-confirmation .page-content > .row {
    margin-bottom: 0 !important; }

body > div#vue-app {
  background: #C30101 no-repeat center center;
  background-image: url("../images/bgcenter.jpg");
  padding: 0; }

html > body {
  background: repeat-y 50% 0 #050505;
  background-attachment: fixed;
  background-image: url("../images/mauer.jpg"); }

div.container, div.container-max, header.container {
  max-width: 1030px; }

.search-input {
  background: #fff; }

.single.container-max.page-content {
  border: 0 none;
  background: transparent; }
  .single.container-max.page-content .singleitem-gray {
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#e5e5e5));
    background: linear-gradient(to bottom, #fff 0, #e5e5e5 100%);
    border: 2px solid #7a1315; }

.no-touch #mainNavbarCollapsable ul.mainmenu > li:hover {
  background-color: transparent; }

.search-box-shadow-frame {
  margin: 0;
  display: block;
  /*padding: 6px;*/ }

#mainNavbarCollapsable ul.mainmenu > li > a {
  padding: 0; }

#mainNavbarCollapsable ul.mainmenu li a {
  padding: 7px 4px;
  font-size: 16px; }
  @media (min-width: 991px) and (max-width: 1199px) {
    #mainNavbarCollapsable ul.mainmenu li a {
      font-size: 16px; } }

.search-submit {
  background: #fff;
  color: #888;
  border-radius: 0 4px 4px 0; }

#login-change {
  font-size: 14px;
  color: #fff; }

.top-bar .list-inline .list-inline-item #login-change a span {
  color: #fff; }

.top-bar .list-inline .list-inline-item a {
  padding: 0.5rem .8rem; }

.top-bar .controls-list > li a:not(.btn):hover, .top-bar .controls-list > li a:not(.btn)[aria-expanded="true"] {
  background-color: transparent;
  color: #fff; }

.top-bar .controls-list > li .basket-preview-hover .basket-item a {
  color: #000 !important; }
  .top-bar .controls-list > li .basket-preview-hover .basket-item a:hover {
    color: #000 !important; }

.top-bar .brand-logo .usp li i {
  color: #77b236;
  margin-right: 2px; }

.top-bar .pipe {
  display: none; }

.list-inline-item.control-basket {
  font-size: 14px !important; }

.add-to-basket-container .btn > .fa {
  float: none !important; }

.navigation-tree li {
  padding: 10px; }

.custom-texts {
  padding: 10px;
  font-size: 16px !important;
  line-height: 1.5 !important;
  color: #000; }
  .custom-texts h1 {
    line-height: 48px;
    font-size: 30px;
    font-weight: 300;
    margin: 12px 0;
    color: black;
    text-rendering: optimizelegibility; }
  .custom-texts strong {
    font-weight: 900; }

.thumb-inner .thumb-title.small {
  color: #000;
  font-size: 14px;
  line-height: 16px;
  height: 52px;
  margin-bottom: 8px;
  overflow: hidden;
  padding: 0;
  text-align: center; }

.thumb-inner .vat-porto-info.small.text-muted {
  display: none !important; }

.thumb-inner .prices {
  display: flex;
  align-items: center;
  justify-content: center; }
  .thumb-inner .prices .price {
    font-size: 20px;
    line-height: 24px;
    color: #77b236 !important;
    text-shadow: #000 1px 1px 0px; }

.thumb-inner .price-view-port {
  display: none !important; }

.container-max.page-content {
  padding: 15px; }
  .container-max.page-content h1 {
    color: #000; }

.home .payment-icons .footer-links-payment {
  padding: 0; }
  .home .payment-icons .footer-links-payment li {
    display: inline; }
    .home .payment-icons .footer-links-payment li a img {
      width: 70px;
      padding-top: 5px;
      padding-right: 0; }

.payment-icons .footer-links-payment {
  padding: 0; }
  .payment-icons .footer-links-payment li {
    display: inline; }
    .payment-icons .footer-links-payment li a img {
      width: 69px;
      padding-top: 5px;
      padding-right: 0; }

.pe-richsnippets.footerLine.text-left {
  /*border-top: 1px solid #fff;*/
  padding-top: 10px; }

.goog-te-gadget-icon {
  display: none; }

.cmp.cmp-product-thumb.cmp-product-thumb--grid {
  border: 1px solid #000; }
  .cmp.cmp-product-thumb.cmp-product-thumb--grid:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.8); }

.vat.small.text-muted {
  color: #000; }

.tab-content img {
  max-width: 99%; }

a {
  color: #000; }

a:hover {
  color: #000; }

.btn.btn-medium {
  width: 11rem; }

.form-control {
  width: 100%;
  padding: 0.6rem 0.75rem; }

.cmp-method-list .method-list-item input:checked + label {
  box-shadow: inset 0 0 0 .1rem #ddd; }

.basket-preview-hover .basketBtn {
  border: 1px solid #000;
  color: #000; }
  .basket-preview-hover .basketBtn:hover {
    background: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%); }

.container-max.page-content {
  color: #000;
  background: linear-gradient(to bottom, #fff 0%, #E5E5E5 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#E5E5E5));
  border: 2px solid #7A1315;
  padding: 10px 20px; }

#CfourItemQuestionsForm .btn {
  background: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%); }
  #CfourItemQuestionsForm .btn:hover {
    background: linear-gradient(to bottom, #383838 0%, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%); }

.similar_article .thumb-inner {
  display: flex; }
  .similar_article .thumb-inner .add-to-basket-lg-container {
    display: none !important; }
  .similar_article .thumb-inner .thumb-image {
    width: 30%; }
  .similar_article .thumb-inner .thumb-content {
    width: 70%; }
    .similar_article .thumb-inner .thumb-content .vat.small.text-muted {
      font-size: 10px; }
  .similar_article .thumb-inner .thumb-meta + div,
  .similar_article .thumb-inner .owl-dots {
    display: none; }
  .similar_article .thumb-inner .thumb-title.small {
    text-align: left;
    height: auto;
    text-transform: none;
    font-size: 14px; }
  .similar_article .thumb-inner .prices .price {
    font-size: 14px;
    color: inherit;
    text-shadow: none; }
  .similar_article .thumb-inner .vat {
    display: none; }

.categoriegrid.page-content.container-max {
  border: 0 none; }

[data-pm="Credit_Card"], [data-pm="Credit_Card"] + .separator {
  display: none; }

.cmp-product-thumb a {
  color: #ffffff; }

/*body > #vue-app > .wrapper-top {
  z-index:2000;
}*/
.autocomplete-item-name {
  flex: 1; }

.search-box-shadow-frame .autocomplete-suggestions > .autocomplete-suggestion {
  white-space: normal; }

.home .product-list .col-12, .home .product-list .col-12 {
  margin: 0; }

body .top-bar .controls-list > li a:not(.btn) {
  color: #fff;
  font-size: 13px; }
