.badge, .tag {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .1rem;
  -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

.page-category .list-controls .pagination {
  float: right;
  li {
    background: #fff;
    &.active {
      background: transparent;
    }
  }
}

.warning-block {
  color: #000;
}

.autocomplete-suggestions {
  max-height: 80vh;
  height: 30vh;
  margin-top: 11px;
  position:absolute;
  z-index: 999;
  .text-truncate {
    white-space: normal;
  }
  img {
    margin-left: 0 !important;
  }
}

.back-to-top i {
  font-size: 29px;
  color: #f7f7f9;
  padding: 4px 6px;
}

.basket-preview-footer a[title="Warenkorb"] {
  border: 1px solid #000;
  color: #000;
  &:hover {
    background: linear-gradient(to bottom,#383838 0,#404040 12%,#4a4a4a 25%,#333 39%,#1f1f1f 50%,#000 51%,#0d0d0d 60%,#1f1f1f 76%,#141414 91%,#0d0d0d 100%);
    color: #fff !important;
    i {
      color: #fff;
    }
  }
}

.top-bar.container #controlsList .basket-preview-wrapper a {
  color: #000;
  &[title="Kasse"] {
    color: #fff;
    max-height: 35px;
  }
  &.item-name {
    float: left;
    padding: 0;
  }
}

.basket-preview-wrapper {
  color: #000;
  width: 100vw;
  .img-basket-small {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
  .qty-box .qty-btn-container {
    width: 25px;
    position: relative;
    /*background: #f4f4f4;*/
    background: #fff;
    /*border: 1px solid #dcdbd8;*/
    border: none;
    border-left: none;
    border-radius: 0 .1rem .1rem 0;
    overflow: hidden;
    height: 100%;
    .qty-box {
      margin: 0 0 5px auto;
      height: 41px;
    }
  }
  .item-remove-container {
    font-size: 1.2em;
    line-height: 1.2em;
    text-align: right;
    & >.item-remove-button {
      cursor: pointer;
      padding: 0;
      background: none;
      color: #d9534f;
      border: none;
    }
  }
  .basket-list-item {
    padding: 1.5rem 0;
    .basket-item.component-loading {
      display: flex;
    }
  }
  .meta-container a {
    /*text-transform: uppercase;*/
    font-weight: 600;
  }
  .basket-preview-hover-wrapper {
    position: absolute;
    width: 100vw;
    right: 0;
    z-index: 99999;
  }
  .basket-preview-content {
    .item-list {
      min-height: auto;
      padding: 0 !important;
      max-width: 100%;
      /*padding: 1.5rem;*/
    }
    &.basket-preview-hover {
      max-height: 90vh;
      padding: 1.5rem;
      flex-direction: column;
      background: #f8f9fa;
    }
  }
  .basket-totals {
    /*padding: 1.5rem;*/
  }
}

.qty-btn-container .qty-btn {
     border: none;
     background-color: transparent;
}

.similar_article .feedback-category-view {
  display: none;
}

.basket, .checkout {
  .basket-item {
       a {
         color: #000 !important;
         &:hover {
           color: #000 !important;
         }
      }
   }
}

.page-login .main > div > div > div {
  background: #fff;
}

.page-login .login-view-title {
  margin-top: 10px;
}

.page-content.basket, .page-content.checkout, .page-content.contact, .page-content.wishlist, .page-content.myaccount {
  margin-top: 30px;
}
