#page-body.main{
  //margin-top: 205px;
}

.page-content{
  background-color: $cfourWhite;
}

.custom-texts{
  &:first-child{
    margin-bottom: 16px;
    margin-top:20px;
  }
  background: $cfourBackgroundGray;
}

.home{
  #subnavigation {
    margin-top: 20px;
  }

  .custom-texts {
    p {
      a {
        color: $cfourBlack;
        text-decoration: underline;
      }
    }
  }
}


.btn-danger{
  background-color: #c30101 !important;
}

@media(max-width: 768px){
  #page-body.main{
    margin-top: 0;
  }
}

/* fixing Ceres 3.0.2 */

.wrapper-main header .container-max > .row {
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: -15px;
  margin-right: -15px;
}

nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu > li > ul.collapse {
  -webkit-column-count: 1;
  column-count: 1;
}

html {

  .top-bar .controls-list .control-user {
    flex: none;
  }

  .list-inline-item {
    justify-content: flex-end;
    display: inline-flex;
  }
    .wrapper-main header .controls-list {
       justify-content: flex-end;
       margin-bottom: 0;
       float: right;
    }

  #CfourDetailedListing .cmp-product-thumb .prices {
    justify-content: left;
  }

  .alert {
    width: 100%;
  }

  .similar-articles {
      .owl-item {
        width: 100% !important;
      }
  }

  .registration .col-sm-10.offset-sm-1.col-md-6.offset-md-3 {
    background: #fff;
    padding: 15px;
  }

  .basket {
    .bg-white {
      background-color: transparent !important;
    }
    #ppButton {
      float: right;
      margin-top: 15px;
      margin-bottom: 25px;
    }
  }

  .btn.btn-link.filter-toggle {
    display: none;
  }

  .cmp-paginator {
    .page-link {
      background: #fff;
      color:#000;
      &:hover {
        color: #000;
      }
    }
    .disabled {
      background: #fff;
    }
  }

}

.basket-item-container .basket-item .meta-container-wrapper .meta-container-wrapper-inner .meta-container .item-name {
  color: $cfourBlack !important;
}

.categoriegrid.page-content .cmp-paginator {
  float: right;
}
