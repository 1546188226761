.search-input{
 /* margin-left: 8px;*/
  height: 30px;
  width: 225px;
  border-radius: 4px 0 0 4px;
  border: 0 none;
  padding: 8px;
}

.search-submit{
  margin-left: -6px;
  border: 0 none;
  width: 34px;
  height: 30px;
  border-left: 1px solid #e5e5e5;
  position: relative;
  top: -1px;
  i{
    font-size: 17px;
  }
}
.search-box-inner{
  display: inline-flex;
  padding: .5rem;
}
.autocomplete-suggestion {
  background: #F1F1F1;
  padding: 5px 15px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction:row;
  //white-space: pre-wrap;
  color: $cfourBlack;
  width:100%;
  &:hover {
    background: linear-gradient(to bottom,#EAEAEA 0,#D7D7D7 100%)!important;
  }
}
.autocomplete-suggestion img {
  max-width: 45px;
  max-height: 45px;
  margin-right: 10px;
}

.preview-img {
  width: 55px;
  margin-right: 5px;
  text-align: center;
}
