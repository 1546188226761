.footer{
  background-color: $cfourBlack;
  padding: 10px 15px 20px;
  color: $cfourWhite;
  .footer-links-payment{
    list-style: none;
    margin: 0;
    li{
      display: inline-block;
      a{
        color: $cfourWhite;
        img{
          width: 70px;
          padding-bottom: 10px;
          padding-right: 6px;
        }
      }
    }
  }
  .footer-links{
    list-style: none;
    margin: 0;
    li{
      a{
        color: $cfourWhite;
          &:hover {
            color: #999;
        }
      }

    }
  }
  .footer-header{
    color: $cfourWhite;
    margin-top: 1rem;
    margin-bottom: 1.25rem;
  }
  #pe_rating {
    display: inline-block;
    font-size: 16px;
    text-align: center;
    color: #c8b274;
    border: none;
    text-decoration: none;
    outline: none;
    #pe_rating.pe_g .pe_u {
      display: block;
      margin: 0 0 .3em;
      font-size: .8em;
      line-height: 1.2em;
    }
    #pe_stars {
       display: inline-block;
       position: relative;
       overflow: hidden;
       width: 5em;
       height: 1.1em;
       margin: 0 !important;
       line-height: 1em;
       font-size: 1em;
     }
  }
  .text-right {
    text-align: right;
    font-size: 16px;
  }
  & > .row {
    /*border-top: 1px solid #fff;*/
    margin: 15px 0;
   & >  div {
      padding: 5px 0 0;
     /* &:last-child {
        padding: 0 !important;
      } */
      & > .row {
        /*border-top: 1px solid #fff;*/
        margin: 15px 0;
       }
    }
  }
  ul {
    padding: 0;
    li {
      list-style: none;
      a {
        color: $cfourWhite;
      }
    }
  }
  .footer-links {
    margin: 0;
    a {
      color: $cfourWhite;
    }
  }

  h3 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 900;
    padding: 5px 0px;
  }
  .footer-payment-bottom {
    display: block;
    text-align: left;
    color: $cfourWhite;
  }

    .to-top {
      text-align: center;
      i.fa.fa-arrow-up {
        font-size: 1.5em;
        padding: .75em 0;
      }
    }

    .btn.text-center.border.mx-auto.rounded-lg.p-0.back-to-top.btn-secondary.pt-1 {
      background: #000;
      opacity: 1!important;
      border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.25);
      -webkit-transition: all .3s ease!important;
      transition: all .3s ease!important;
      -webkit-box-shadow: 0 0 10px 3px #fff;
      box-shadow: 0 0 10px 3px #fff;
        i {
            font-size: 29px;
            color: #f7f7f9;
            padding: 0 6px;
        }
    }
    .btn.btn-secondary.d-block.d-md-none.text-center.p-2 {
        background: -webkit-gradient(linear,left top,left bottom,from(#383838),color-stop(12%,#404040),color-stop(25%,#4a4a4a),color-stop(39%,#333),color-stop(50%,#1f1f1f),color-stop(51%,#000),color-stop(60%,#0d0d0d),color-stop(76%,#1f1f1f),color-stop(91%,#141414),to(#0d0d0d));
        background: linear-gradient(to bottom,#383838 0,#404040 12%,#4a4a4a 25%,#333 39%,#1f1f1f 50%,#000 51%,#0d0d0d 60%,#1f1f1f 76%,#141414 91%,#0d0d0d 100%);
        -webkit-box-shadow: 0 0 15px 5px #fff;
        box-shadow: 0 0 15px 5px #fff;
        border-color: #000;
        i {
          font-size: 1.5em;
          padding: 0.75em 0;
        }
    }

    .cookie-bar {
        button {
            border-color: #000 !important;
        }
        .btn-appearance {
           background: -webkit-gradient(linear,left top,left bottom,from(#383838),color-stop(12%,#404040),color-stop(25%,#4a4a4a),color-stop(39%,#333),color-stop(50%,#1f1f1f),color-stop(51%,#000),color-stop(60%,#0d0d0d),color-stop(76%,#1f1f1f),color-stop(91%,#141414),to(#0d0d0d));
           background: linear-gradient(to bottom,#383838 0,#404040 12%,#4a4a4a 25%,#333 39%,#1f1f1f 50%,#000 51%,#0d0d0d 60%,#1f1f1f 76%,#141414 91%,#0d0d0d 100%);
           color: #fff;
        }
         .custom-control-appearance .custom-control-input:checked ~ .custom-control-label::before {
            background-color: #000;
            border-color: #000;
        }
        .text-appearance {
            color: #000 !important;
        }
    }
    .widget-grid {
       & > .widget-inner {
           padding-left: 0;
       }
    }
}
