.top-bar.container {
  padding: 0;
  background-color: #C30101 !important;
  transition: all 0.2s linear 0.2s;

  .brand-logo {
    /*@media (min-width:768px) {
      overflow: hidden;
    }*/
    width: 100%;
    background-color: $cfourBackgroundGray;

    .usp {
      display: inline-flex;
      width: 50%;
      margin: 10px 0;
      vertical-align: middle;
      transform: translateX(50%);
      opacity: 1;

      li {
        color: black;
        display: inline-block;
        width: 50%;

        i {
          color: #77b236;
        }

      }
    }
  }

  #controlsList {
    a {
      font-size: 15px;
      &:hover {
        color: $cfourWhite;
        //color: #999;
      }
    }

    .control-basket {
      .toggle-basket-preview {
        background-color: $cfourTransparentColor !important;
      }
    }

    .control-languages {
      position: absolute;
      left: 150px;
    }
  }
}

.top-bar.container {
  * {
    transition: all 0.2s linear 0s;
  }
}

.wrapper-main {
  .navbar {
    border-bottom: none !important;
  }

  .brand-logo {
    img {
      /*position: absolute;*/
      /*top: 70px;*/
      margin-top: -30px;
    }
  }

  #page-body {
    padding: 0 15px 15px 15px;
  }
}


.breadcrumb-item + .breadcrumb-item::before {
  content: "\f101";
  font-family: FontAwesome;
  line-height: 1;
  color: $cfourWhite;
  margin: 0 15px;

}

.isScrolled {
  .top-bar.container {
    padding: 0;
    background-color: $cfourBackgroundGray !important;
    transition: all 0.2s linear 0s;

    .translations {
      opacity: 0;
      transition: all 0.2s linear 0s;
    }

    .brand-logo {
      background-color: $cfourBackgroundGray;
      height: 22px;

      img {
        height: 55px;
        margin-top: -165px;
      }

      .usp {
        display: inline-flex;
        width: 50%;
        margin: 10px 0;
        vertical-align: middle;
        transform: translateX(50%);
        opacity: 0;
      }
    }

    #controlsList {
      .control-home {
        div, i {
          color: #555;
        }
      }

      #login-change {
        i {
          color: #555;
        }

        span {
          color: #555;
        }
      }

      a {
        &:hover {
          color: $cfourWhite;
        }

        span {
          color: #555;
        }

        i {
          color: #555;
        }
      }
    }
  }
}

.top-bar-header {
  padding: 6px 12px;

  .translations {
    margin-top: 2px;
    opacity: 1;
    transition: all 0.2s linear 0s;

    i {
      font-size: 26px;
      margin-top: 1px;
      margin-left: 6px;
    }
  }
}

.goog-te-gadget-simple {
  .goog-te-menu-value {
    span {
      color: $cfourBlack;
    }
  }
}

.brand-logo .search-box-inner {
  width: 100%;
  @media (max-width: 767px) {
    float: right;
  }

  .search-box-shadow-frame {
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
  }
}

@media (max-width: 767px) {
  .translations {
    height: 27px;
    overflow: hidden;
  }
  .isSwiped {
    .translations {
      height: 0;
    }

    .usp li {
      display: none !important;
    }

    .wrapper-main .brand-logo img {
      top: 56px;
      transition: none;
    }

    .top-bar.container .brand-logo #btnMainMenuToggler {
      top: 60px;
    }
  }
}
