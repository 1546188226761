@media (min-width: 992px) and (max-width: 1199px) {
  .top-bar.container {
    .brand-logo {
      .usp {
        width: 52%;
      }
    }
  }
}

@media (min-width: 768px) {
 html .autocomplete-suggestions {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  html .wrapper-main .breadcrumb {
    padding-top: 40px !important;
  }

  .top-bar-header .translations {
    padding-left: 30px;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .navbar-brand {
    img {
      margin-left: 12px;
    }
  }

  .top-bar.container {
    .brand-logo {
      img {
        margin-top: -5px;
      }
      .usp {
        display: inline-flex;
        width: 62%;
        margin-left: -30%;
      }
    }
  }
  .isScrolled {
    .brand-logo {
      img {
        margin-top: -115px !important;
      }
    }
  }

  .home {
    .usp.list-unstyled.row {
    }
  }

  .payment-icons {
    h3 {
      font-size: 20px;
    }
  }

  .text-muted.small.add-to-wish-list {
    float: left;
    width: 262px;
  }

  span.price.h1 {
    float: none;
  }

  hr {
    border: 1px solid $cfourTransparentColor;
  }

  .add-to-basket-container {
    .quantity-input-container {
      margin-right: 15px;
    }
  }

  .single-rightside {
    .btn-block {
      width: 187px;
    }
  }

  .breadcrumb {
    padding-top: 0;
    margin-top: -35px !important;
  }
}

@media (max-width:991px) {

  .top-bar {
    .controls-list > li a:not(.btn) {
      color: $cfourWhite;
    }
  }

  .basket-preview-hover {
    .basketBtn:hover,
    .checkOutBtn:hover {
      i {
        color: $cfourWhite;
      }
    }
  }

  .btn-outline-primary {
    border-color: $cfourBlack;
    color: $cfourBlack;
    //margin-top: 20px;
    margin-bottom: 20px;
     &:hover {
       background: $cfourGradientBackground;
       border-color: $cfourBlack;
     }
  }

  #subnavigation {
    display: none;
  }

  .box-gradient-header {
    h3 {
      margin-top: 25px;
    }
  }

  .navbar-brand {
    @media (min-width: 768px) {
      padding-top: 1rem;
      padding-bottom: 3.5rem;
    }
    img {
      margin-left: 25px;
    }
  }

  .mobile-navigation {
    background: $cfourBlack;
    ul.breadcrumb {
      background: $cfourBlack;
      border-bottom: 1px solid #fff;
      margin-right: 15px;
      margin-left: 15px;
    }
  }

  .wrapper-main {
    .navbar {
      .mainmenu {
        li {
           a:last-child {
            margin-right: 20px;
            margin-left: 20px;
            border-bottom: 1px solid #fff;
          }
        }
      }
    }
  }

  .btn-outline-primary:active:focus, .btn-outline-primary:active:hover {
        color: #fff;
        background-color: $cfourPriceGreen;
        border-color: $cfourPriceGreen;
  }

  }




@media (max-width:767px) {
  .top-bar.container {
    #controlsList{
      justify-content: flex-end;
      .control-user{
        flex: 0;
        display: contents;
      }
      li {
        display: inline-flex !important;
      }
    }
    .brand-logo {
      .navbar-brand {
        margin-left: 10%;
      }
      .usp {
        /*width: 100%;
        margin-top: 52px;*/
        transform: translateX(0%);
        li{
          font-size: 15px;
        }
      }
      #btnMainMenuToggler{
        /*border: 1px solid #ccc;*/
        border-radius: 10%;
       /* color: #888;*/
        position: absolute;
       /* top: 30px;*/
        margin: 0!important;
        height: 35px;
        right: 1rem !important;
        left: auto;
      }
    }
  }

  .footer {
    .to-top {
      background: $cfourGradientBackground;
      -webkit-box-shadow: 0 0 15px 5px #fff;
      box-shadow: 0 0 15px 5px #fff;
    }

    .footer-payment-bottom,
    .text-right {
      text-align: left;
      font-size: 12px;
    }
  }


}

@media (max-width: 767px) {

  .text-muted.small.add-to-wish-list {
    padding: .55rem 0;
    float: left;
    width: 260px;
    text-align: center;
  }

  .add-to-basket-container {
    width: 260px;
  }

  .navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .1rem;
  }
    .search-input {
      margin-left: 0;
      flex-grow: 1;
      flex: 0 0 85%;
      max-width: 80%;
      width: 100vw;
    }
}


@media (max-width: 543px) {

  .navbar-brand img {
    margin-left: 20px;
  }

  .wrapper-main {
    .brand-logo {
      img {
        top: 90px;
      }
    }
  }

  .cmp-product-thumb {
    .thumb-image,
    .thumb-content {
      width: 100%;
    }
    .thumb-content {
      margin: 0;
      padding: 0;
        .thumb-title.small {
          margin-top: 20px;
      }
    }
  }

  .isScrolled {
    .top-bar.container {
      .brand-logo {
        img {
          top: 62px;
        }
      }
    }
  }


  span.price.h1 {
    font-size: 37px;
  }

  #btnMainMenuToggler {
    margin-top: 20px;
  }

  .list-controls {
    .list-sort {
      padding: 0 0 0 10px;
    }
  }

  .footer {
    hr {
      border-top:none;
    }
    .row {
      border-top: none;
    }

    .footer-links-payment {
      li {
        a {
          img {
            width: 69px;
          }
        }
      }
    }
  }

}


@media (max-width: 480px) {
  .list-controls {
    .list-sort {
      padding: .5em;
    }
  }
}

@media (max-width:768px) {
  .top-bar{
    position: relative;
  }
}

@media (min-width: 544px){
  .brand-logo{
    .usp {
      transform: translateX(10%);
    }
  }
}

.modal-open[style],
#page-header[style]{
  padding-right: 0 !important;
}
.m-y-1 > .col-4, .my-1 > .col-4  {
  float: left;
}
.m-y-1 > .col-12, .my-1 > .col-12 {
  clear:both;
}

.widget.widget-image-box.widget-image-box-no-caption.widget-primary.widget-proportional {
  padding:0;

}
.widget.widget-image-box.widget-image-box-no-caption.widget-primary.widget-proportional img{
  position: relative;
  transform: none;
  left:auto;
}

.home .payment-icons .footer-links-payment {
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.feedback-stars-average.feedback-category-view {
  margin: 0 auto;
}

@media (min-width: 768px) and (max-width: 991px){

  .search-box-inner {
    position: relative;
    left: 1.5rem;
    width: 100vw !important;
    margin: 6px 0;
    .search-box-shadow-frame {
      width: 100% !important;
      .search-input {
        width: 91%;
      }
    }
  }
  .isScrolled {
    .search-box-inner {
      position: relative;
      top: -85px;
    }
    #btnMainMenuToggler {
      top: 20px;
    }
  }

  .usp.list-unstyled.row li {
    font-size:10px;
    max-width: 33.33% !important;;
  }
}


@media (max-width:767px){
  .brand-logo {
    position: relative;
    img {
      margin-top: 10px !important;
    }
    .usp {
      flex: 0 0 80%;
      max-width: 80%;
      width: 100% !important;
    }
  }
  .navbar-brand { padding :0;}
  .brand-logo * {
    transition: all 0.0s linear 0s !important;
  }
  .isSwiped .top-bar.container .brand-logo #btnMainMenuToggler {
    top: 30px;
  }
  .search-box-inner {
    position: absolute;
    top: -133px;
    //width: calc(100vw - 225px)!important;
    left: 250px;
    padding: 10px 17px;
  }
  .isSwiped .search-box-inner {
    position:absolute;
    top: -60px;
    //width: calc( 100vw - 205px) !important;
    left: 185px;
    @media (max-width: 575px) {
      top: -200px;
    }
  }

  .isSwiped .top-bar.container .brand-logo {
    padding-top: 8px;
  }
  .brand-logo > a > img {
    margin: 5px 0 0 0 !important;
    transition: all 0.1s linear 0s !important;
  }
  .isSwiped .wrapper-main .brand-logo > a > img {
    top: 5px !important;
    width: 103px !important;
    height: auto !important;
  }
  input.search-input {
    width: 100px;
  }

  /*.brand-logo .search-box-inner {
    position: absolute;
    top:0;
  }*/
}

.modal-footer .btn.btn-outline-primary.btn-medium.mr-2 {
  margin-bottom: 0;
}
.add-to-basket-container .col-12.col-sm-6.col-md-12 {
  width: 100%;
  flex: 1 1 100%;
  max-width: 100%;
}
.CfourQuantityInput {
  max-width: 100%;
}
.add-to-basket-container {
  width: auto;
}
.search-box-shadow-frame .autocomplete-suggestions {
  right:0;
  width:90vw;
}

//.search-box-shadow-frame .autocomplete-suggestions>.autocomplete-suggestion>.autocomplete-image-container>.autocomplete-image {
.search-box-shadow-frame .autocomplete-suggestions .autocomplete-image {
  @media (min-width: 992px) {
    transform: none;
    position: static !important;
  }
  @media (max-width: 991px) {
    position: unset !important;
    top: 0 !important;
    margin-left: 0;
  }
}



  @media (max-width: 575px) {
    .brand-logo {
      .usp {
        max-width: 100%;
         li {
          font-size: 12px !important;
           padding: 0 0px 0 7px;
        }
      }
      .navbar-brand {
        margin-left: 55px !important;
      }
    }
    .search-box-inner {
      top: -123px;
      left: 175px;
      width: 100%;
      .search-input {
        max-width: 80%;
        width: 32vw;
      }
    }

    .isSwiped {
      .search-box-inner {
        top: -65px;
        left: 162px;
      }
      #btnMainMenuToggler {
        top: 33px !important;
      }
    }
    #btnMainMenuToggler {
      left: 9px;
    }
  }
