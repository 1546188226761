.page-confirmation {
  .page-content {
    background: linear-gradient(to bottom,#f2f2f2 0%,#E5E5E5 100%) !important;
    padding-bottom: 0 !important;
    .confirmation-button-panel {
      a, .invoice {
        background: linear-gradient(to bottom, #383838 0, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
        border: none;
      }
    }
    .text-appearance {
      color: #000 !important;
    }
    .widget-feedback-orderconfirmation {
      background: #fff;
      border: 1px solid #dee2e6;
      .feedback-container.feedback-orderitem-container {
        text-align: center;
        margin: auto;
        padding: 0 15px;
        //@media (min-width: 992px) {
        //  max-width: 50%;
        //}
        .title {
          font-size: 1.5rem;
        }
        .feedback-item-link a {
          font-size: 100%;
          margin-top: 10px;
          background: #fff;
        }
      }
      a {
        background: linear-gradient(to bottom, #383838 0, #404040 12%, #4a4a4a 25%, #333 39%, #1f1f1f 50%, #000 51%, #0d0d0d 60%, #1f1f1f 76%, #141414 91%, #0d0d0d 100%);
        border: none;
      }
    }
    .widget-order-data, .widget-purchased-items, .widget-order-totals {
      background: #fff;
      font-size: 80%;
    }
    & > .row {
      margin-bottom: 0 !important;
    }
  }
}
