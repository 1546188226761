.faq {
  .faq-section {
    .faq-item {
      margin-bottom: 10px;
    }
    .faq-question {
      border-radius: 5px;
      background: #555;
      color: #fff;
      padding: 10px 10px 10px 35px;
      position: relative;
      cursor: pointer;
    }
    .faq-question.open-faq {
      border-radius: 5px 5px 0 0;
    }
    .faq-question.open-faq+.faq-answer {
      height: auto;
      -webkit-transition: all .25s;
      transition: all .25s;
      display: block;
    }

    .faq-question.open-faq+.faq-answer {
      height: auto;
      -webkit-transition: all .25s;
      transition: all .25s;
      display: block;
    }

    .faq-question.open-faq {
      .fas.fa-chevron-down {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        top: 9px;
      }
    }
    .faq-answer {
      border: 1px solid #555;
      padding: 10px 10px 0;
      border-radius: 0 0 5px 5px;
      overflow: hidden;
      height: 0;
      display: none;
    }
    .faq-question {
      .fas.fa-chevron-down {
        position: absolute;
        top: 13px;
        left: 12px;
        z-index: 1;
        -webkit-transition: all .25s;
        transition: all .25s;
        font-family: FontAwesome;
      }
    }

  }
  .faq-summary {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
    li {
      border: 1px solid #555;
      border-bottom: none;
    }
    li:last-child {
      border-bottom: 1px solid #555;
    }
    label {
      padding: 10px;
      background: #e9e9e9;
      display: block;
      cursor: pointer;
    }
    label.active {
      background: #555;
      color: #fff;
      -webkit-transition: all .25s;
      transition: all .25s;
      margin-bottom: 0;
    }

  }
}


