.parallax-img-container .parallax-text-container h1 {
  margin-top: 50px;
}

.list-controls .list-controls-inner {
  background-color: $cfourSecondaryColor;
  padding: .5rem;
}

.Cfourhidden {
  display: none;
}

#CfourListingSwitch {
  margin-bottom: 20px;
  form {
    display: inline-block;
  }
  .switchButton {
    border: none;
    background: transparent;
    color: $cfourBlack;
    font-size: 25px;
    &:hover, &.active {
      color: $cfourSecondaryColor;
    }
  }
}

.cmp-product-thumb {
  border: 1px solid $cfourBlack;

  .thumb-background, .main-image, .thumb-content {
    background-color: transparent;
  }

  .add-to-basket-lg-container {

    & > i {
      color: $cfourWhite;
    }

    &:hover {
      border-color: transparent $cfourSecondaryColor transparent transparent;

      & > i {
        color: $cfourWhite;
      }
    }
  }

  .crossprice {
    color: $cfourSecondaryColor;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.1;
  }

  .price {
    color: $cfourPriceGreen;
    font-weight: 900;
    font-size: 20px;
    text-align: right;
  }

  .price-col {
    padding: 0;
    left: 50%;
  }

  .price-view-port-col {
    padding: 0;
    text-align: right;

    & + .price-col {
      left: 0;
    }
  }

  .vat-porto-info, .vat {
    text-align: right;
    display: inline-block;
    width: 100%;
    margin-top: 6px;
    color: $cfourPrimaryColor !important;

    & > a {
      color: $cfourPrimaryColor;
      text-decoration: underline;
    }
  }

  .special-tags {
    padding: 18px;

    .special-tag, .tag {

      &.bg-danger {
        background-color: #FFCBFF !important;
        color: $cfourPrimaryColor !important;

        & > i {
          padding: 5px 7px;
        }

        &::after {
          border-color: transparent #ffcbff transparent;
        }
      }

      &.bg-primary {
        background-color: #C4DCFE !important;
        color: $cfourWhite !important;

        & > i {
          padding: 5px 6px;
        }

        &::after {
          border-color: transparent #C4DCFE transparent;
        }
      }

      &.bg-success {
        background-color: #30f292 !important;
        color: $cfourWhite !important;

        & > i {
          padding: 2px 12px;
          font-size: 31px;
        }

        &::after {
          border-color: transparent #30f292 transparent;
        }
      }
      border-radius: 5px;
      padding: 5px;
      box-shadow: none;
      position: relative;

      & > i {
        font-size: 25px;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        right: 8px;
        bottom: -12px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 12px 0;
      }
    }
  }

  .thumb-title {
    font-size: 16px;
    text-transform: uppercase;
    color: $cfourPrimaryColor;

    & > small {
      text-transform: none;
      font-size: 100%;
      line-height: 30px;
      display: inline-block;
      margin-bottom: 10px;
    }
  }

  @include media-breakpoint-down(lg) {
    .price, .crossprice {
      font-size: 16px;
    }
  }

  @include media-breakpoint-down(md) {
    .mobile-icon-right {
      float: none;
    }
  }

  @include media-breakpoint-down(pxs) {
    .price, .crossprice {
      font-size: 14px;
      min-height: unset;
      line-height: 20px;
    }
  }

  @include media-breakpoint-down(xs) {
    .special-tags {
      padding: 0;

      .tag {
        padding: 0;

        & > i {
          font-size: 18px;
        }

        &::after {
          right: 6px;
          bottom: -7px;
          border-width: 0 10px 7px 0;
        }

        &.bg-success > i {
          padding: 4px 11px;
          font-size: 20px;
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .thumb-content, .thumb-image {
    width: 100%;
  }
  .CfourAddToBasket {
    .btn-outline-primary {
      i {
        display: inline-block;
      }
      .mobile-text-only {
        display: none;
      }
    }
  }
}

#CfourDetailedListing {
  #vue-rendered-item-list {
    display: block !important;
    .CfourCategoryItem {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
      float: left;
      width: 100%;
    }
  }
  .tilesView {
    display: none;
  }
  .product-list {
    li {
      display: block;
      padding: 0 .5rem;
    }
  }
  .cmp-product-thumb {
    border: none;
    padding-bottom: 15px;
    width: auto;
    float: none;
    .CfourArticleTable {
      width: 100%;
    }
    .availability.tag {
      white-space: normal;
    }
    .CfourListingButtons {
      margin-top: 30px;
      .category-list-view-port {
        display: block;
      }
      .CfourAddToBasket {
        .category-list-view-port {
          .add-to-basket-lg-container {
            background-color: $cfourBlack;
            border: none;
            position: relative;
            height: auto;
            margin-bottom: 15px;
            width: 100%;
            & > * {
              display: block;
              text-transform: uppercase;
              font-weight: bold;
              color: $cfourWhite;
              margin: 0;
            }
            &:hover {
              border-color: transparent $cfourSecondaryColor transparent transparent;
              & > i {
                color: $cfourPrimaryColor;
              }
            }
          }
        }
        .add-to-basket-lg-container {
          border: none;
          display: table;
          position: relative;
          height: 38px;
          margin-bottom: 15px;
          width: 100%;
          & > * {
            text-transform: uppercase;
            font-weight: bold;
            color: $cfourWhite;
            position: static;
            text-align: center;
            vertical-align: middle;
            display: table-cell;
          }
          &:hover {
            border-color: transparent $cfourSecondaryColor transparent transparent;
            & > i {
              color: $cfourPrimaryColor;
            }
          }
        }
        .btn-group {
          opacity: 1;
          width: 100%;
          position: static;
          margin-bottom: 10px;
          button {
            background-color: $cfourSecondaryColor;
            width: 100%;
            border: none;
          }
        }
      }
      .CfourListingMoreButton {
        background-color: $cfourBlack;
        border: none;
        display: table;
        position: relative;
        height: 38px;
        width: 200px;
        & > * {
          text-transform: uppercase;
          font-weight: bold;
          color: $cfourWhite;
          position: static;
          text-align: center;
          vertical-align: middle;
          display: table-cell;
        }
      }
    }

    .thumb-background, .main-image, .thumb-content {
      background-color: transparent;
    }

    .producerimage {
      img {
        width: 100%;
      }
    }

    .crossprice {
      color: $cfourSecondaryColor;
      font-size: 24px;
      font-weight: normal;
    }

    .price {
      color: $cfourPriceGreen;
      font-weight: 700;
      font-size: 26px;
      text-align: left;
    }

    .vat-porto-info, .vat {
      text-align: left;
      margin-top: 6px;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 1033px) {
    .price {
      font-size: 30px !important;
    }
  }
  @media (max-width: 767px) {
    .cmp-product-thumb .thumb-image {
      width: 100%;
    }
    .thumb-content {
      width: auto;
    }
    .crossprice {
      font-size: 14px !important;
    }
    .price {
      font-size: 18px !important;
    }
  }
  @media (max-width: 543px) {
    .thumb-content {
      margin-top: 20px;
    }
  }
  @media (min-width: 768px) {
    .Cfour-visible-xs {
      display: none;
    }
  }
  .feedback-stars-average.feedback-category-view {
    float: left;
  }
}

#CfourTileListing {
  #vue-rendered-item-list {
    display: block !important;
  }
  .CfourCategoryItem {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
    width: 100%;
  }
  @media (min-width: 544px) {
    .CfourCategoryItem {
      width: 50%;
    }
  }
  @media (min-width: 768px) {
    .CfourCategoryItem {
      width: 33.33333%;
    }
  }
  @media (min-width: 992px) {
    .CfourCategoryItem {
      width: 25%;
    }
  }
  .detailedView {
    display: none;
  }
}

@media (max-width: 991px) {
  .cmp-product-thumb {
    .thumb-content {
      margin-top: 15px;
    }

    .special-tags .tag {
      padding: 5px !important;
    }

    .add-to-basket-lg-container {
      border: 1px solid #014FA4 !important;
      padding: 6px 10px;
      position: relative;
      width: 100%;
      border-radius: 2px;
      color: #014FA4;
      background: #FFFFFF;
      margin-top: 10px;

      p {
        margin-bottom: 0;
      }

      &:hover, &:focus {
        border-color: #014fa4;
      }
    }

    a {
      color: #014fa4;
    }
  }
}
