.wrapper-main{
  .navbar{
    background: $cfourGradientBackground !important;
    padding: 0;
  }
  div#mainNavbarCollapsable{
    ul.mainmenu{
      display: inline-flex;
      margin: 0;
      padding-left: 0.5rem;
      li{
        ul.collapse{
          min-width: 17em;
        }
      }
    }
      li{
        a {
          color: $cfourWhite ;
          font-weight: bold;
          //margin-right: 20px;
          //margin-left: 20px;
          //border-bottom: 1px solid #fff;
        }
        // a:last-child {
        //  margin-right: 20px;
        //  margin-left: 20px;
        //  border-bottom: 1px solid #fff;
        //}
      }
    }
  }

#subnavigation{
  h3{
    background: $cfourGradientBackground;
    color: $cfourWhite;
    margin: 0;
    text-align: center;
    padding: 4px;
  }
  .subnavi{
    list-style: none;
    padding: 0;
    li{
      background: $cfourSubnavigationBackground;
      padding:0;
      &:hover{
        a{
          color: $cfourLinkHoverColor;
        }
        border-color: rgba(0,142,189,0.5);
      }
      a{
        padding:6px;
        display: block;
        color: black;
        transition: all .5s;
        font-size:13px;
      }
    }
  }
}

.payment-icons{
  h3{
    background: $cfourGradientBackground;
    color: $cfourWhite;
    margin: 0;
    text-align: center;
  }
}

.box-gradient-header{
  h3{
    background: $cfourGradientBackground;
    color: $cfourWhite;
    margin: 0;
    text-align: center;
    padding: 6px;
  }
}

#btnMainMenuToggler {
    color: $cfourBlack;
    z-index: 999;
  @media (min-width: 768px) and (max-width: 991px) {
    right: 2rem;
    left: auto !important;
    position: absolute;
    top: 5rem;
  }
}

.top-bar {
  .controls-list {
    .control-user {
      margin-left: 0;
      #login-change > .dropdown {
        .dropdown-menu {
          a {
            &:hover {
              background-color: $cfourMainContentRed;
            }
          }
        }
      }
    }
    .control-home {
        div {
          color: $cfourWhite;
          font-size: 15px;
          i {
            color: $cfourWhite;
            font-size: 15px;
            padding-right: 2px;
        }
      }
    }
  }
}



.cmp-address-list {
  .item input:checked + .item-inner {
    color: $cfourBlack;
    background-color: #9994;
    box-shadow: inset 0 0 0 .1rem #777;
  }
}

.page-item{
  &.active{
    .page-link{
      background-color: transparent;
      border: 2px solid #fff;
    }
  }
  &.disabled{
    .page-link{
      background-color: transparent;
      border: 2px solid #000;
    }
  }
  .page-link{
    background-color: transparent;
    border: 2px solid #000;
    height: 32px;
    color: #000;
    i{
      color: $cfourBlack;
    }
  }
}

.navDropdown2{
  background-color: #F1F1F1 !important;
  .level1{
    a{
      color: $cfourBlack !important;
      &:hover{
        color: $cfourBlack !important;
      }
    }
  }
}




@media (min-width: 992px) {
  nav.navbar.megamenu {
    #mainNavbarCollapsable {
      ul.mainmenu{
        li{
          ul.collapse {
            min-height: 0;
          }
        }
      }
      }
      //ul.mainmenu > li > ul.collapse > li:hover {
      //  //background: -webkit-gradient(linear, left top, left bottom, from(#EAEAEA), to(#D7D7D7)) !important;
      //  //background: linear-gradient(to bottom, #EAEAEA 0%, #D7D7D7 100%) !important;
      //  border: 1px solid #000;
      //  color: #000;
      //}
      ul.mainmenu  {
        li.level1 {
          padding: .3em .8em !important;
          border: 1px solid transparent;
          a {
            color: #000 !important;
            //&:hover {
            //  background: -webkit-gradient(linear, left top, left bottom, from(#EAEAEA), to(#D7D7D7)) !important;
            //  background: linear-gradient(to bottom, #EAEAEA 0%, #D7D7D7 100%) !important;
            //}
          }
         }

      }
        }
      }
  ul.mainmenu > li {
    position: relative !important;
    &:hover {
      background: $cfourTransparentColor !important;
    }
  }




  .ddown:hover {
    background: $cfourTransparentColor !important;
  }

  //#navDropdown1 li.level1:hover > * {
  //  background: -webkit-gradient(linear, left top, left bottom, from(#EAEAEA), to(#D7D7D7)) !important;
  //  background: linear-gradient(to bottom, #EAEAEA 0%, #D7D7D7 100%) !important;
  //}
//
@media(max-width: 767px){
    .wrapper-main{
      .brand-logo{
        img{
          top: 65px;
          height: 50px;
          left: 50%;
          margin-left: -64px;
        }
        .navbar-toggler{
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0,-50%);
        }
      }
    }
  #page-body.main {
    margin-top: 0;
    .breadcrumb {
      font-size: 1rem;
      padding: 0;
      background: transparent;
      .breadcrumb-item {
        &:before {
          margin: 0;
        }
      }
    }
  }
}

.mainmenu{
  .ddown{
    a{
      &::after{
        content: "" !important;
      }
    }
  }
}

#navigation-links{
  background: $cfourWhite;
  li{
    padding: 10px 0;
    a{
      color: $cfourBlack;
      font-weight: bold;
    }
  }
}

nav.navbar.megamenu{
  #mainNavbarCollapsable{
    ul.mainmenu{
      li.ddown{
        ul.collapse.navDropdown{
          background-color: $cfourNavBackground !important;
          padding: 0;
          margin-top: 9px;
          li{
            padding-left: 15px;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #ddd;
            background-color: $cfourNavBackground !important;
            &:hover{
              background: $cfourNavBackgroundHover !important;
              /*a{
                background: $cfourNavBackgroundHover !important;
              }*/
            }
            a{
              color: #444 !important;
              text-align: left;
              /*background-color: $cfourNavBackground !important;*/
              width: 100%;
              /*border-bottom: 1px solid #ddd;*/
              font-weight: normal;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
.basket-small ul .item-property-value > strong:after {
  content: none !important;
}



