.modal-dialog {
  .modal-footer {
    .btn.btn-primary.btn-medium {
      background: $cfourBlack;
    }
    .reset-pwd-direction{
      margin-left: 15px;
      margin-top: 5px;
      a {
        color: $cfourBlack;
        font-size: 15px;
      }
      a:hover {
        color: $cfourBlack;
      }
    }

  }
  .modal-content {
    background: #E9E9E9;
    .modal-header {
      .close {
        opacity: 1;
      }
    }
  }
}


#vue-app {
  .btn-primary {
    background: $cfourGradientBackground;
    color: $cfourWhite;
  }
  .input-unit{
    border-radius: 4px;
    border: 1px solid #dcdbd8;
    .input-unit-label,
    label {
      text-transform: none;
      font-weight: 500;
    }

  }
  input.search-input{
    color: $cfourBlack;
  }
}
.page-content {
  .btn-danger {
    background: #ff0000;
  }
}
.nav-tabs {
  .nav-link.active {
    color: $cfourWhite;
  }
  .nav-link.active:hover {
    color: $cfourWhite;
  }
}
